import React from "react";
import { useNavigate } from "react-router-dom";
import "./pageTabs.css";
import { Container } from "react-bootstrap";

function PageTabs({ refs, ScrollHandler }) {
  return (
    <div >
      <Container fluid className="TabContainer" style={{ width: "100%" }}>
        <text className="PageTabsTitle">Müellif Hakkında</text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.Hayat);
          }}
        >
          {"> Kısa Hayat Hikayesi"}
        </text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.Takdim);
          }}
        >
          {"> Takdim"}
        </text>
        <text className="PageTabsTitle">Lugat Hakkında</text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.Önsöz);
          }}
        >
          {"> Önsöz"}
        </text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.Yardımcılar);
          }}
        >
          {"> Yardımcılar"}
        </text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.DanışmaKurulu);
          }}
        >
          {"> Danışma Kurulu"}
        </text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.ÖzelAlanDanışmanları);
          }}
        >
          {"> Özel Alan Danışmanları"}
        </text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.Ödüller);
          }}
        >
          {"> Ödüller"}
        </text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.Baskılar);
          }}
        >
          {"> Baskılar"}
        </text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.KısaBilgiler);
          }}
        >
          {"> Kısa Bilgiler"}
        </text>
        <text
          className="PageTabsSubtitle"
          onClick={() => {
            ScrollHandler(refs.Basında);
          }}
        >
          {"> Basında"}
        </text>
      </Container>
    </div>
  );
}

export default PageTabs;
