import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Image,
  InputGroup,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./fixedHeader.css";
import { Colors } from "../../assets/Colors";
import {
  MenuSVG,
  SearchSvg,
  SearchinTextSvg,
  KeyboardSvg,
  ShuffleSvg,
  KubbealtıLogoSVG,
} from "../../assets/svg";
import Line from "../Line/Line";
import Search from "../Search/Search";

import Sticky from "react-stickynode";
import { axiosInstance } from "../../axios/Axios";
import { useNavigate } from "react-router-dom";

import Keyboard from '../Keyboard/Keyboard';

import parse from "html-react-parser";
import KeyboardDeneme from "../KeyboardDeneme/KeyboardDeneme";

import Swal from 'sweetalert2';

function FixedHeader({ word, setMainWord }) {
  const logo = require("../../assets/img/lugatLogo.png");
  const Ayverdi = require("../../assets/img/ayverdi2.png");
  const navigate = useNavigate();

  const [searchInText, setSearchInText] = useState(false);
  const [keyboardVisual,setKeyboard]=useState(false);

  const searchInTextHandler = () => {
    setSearchInText(!searchInText);
    // swal("Metin içi arama : "+(!searchInText?"Etkin":"Etkin Değil"),"", "warning");
    Swal.fire({
      title:`<link style={{fontWeight: 'normal'}}>Metin içi arama: ${(!searchInText?"Etkin":"Etkin Değil")}</link>`,
      customClass:{
        title:"Text1",
      },
      // titleText:"umuaz",
      // text:"<h6>muaz</h6>",
      html:"Metin içi arama etkin olduğunda kelime anlam ve misal bölümlerinde aranır.",
      icon:'info',
      confirmButtonText:"Tamam",
      confirmButtonColor:'#48A3BD'
    });
  };

  const suggestWord = async () => {
    let response;
    try {
      response = await axiosInstance.get(`suggest-word`);
      if (response.data.kelime != undefined) {
        navigate(`/s/${response.data.kelimeSiralama}`);
        // window.location.reload();
      } else {
        suggestWord();
      }
    } catch (error) {
      suggestWord();
    }

    // navigate(`/s/${value}`);
    // window.location.reload();
  };


  const tooltip = (
    <Tooltip id="tooltip">
      <strong>
        Metin İçi Arama <br /> {searchInText ? "(Etkin)" : "(Etkin Değil)"}
      </strong>
    </Tooltip>
  );

  const tooltipKeyboard = (
    <Tooltip id="tooltip">
      <strong>Klavye</strong>
    </Tooltip>
  );

  const tooltipShuffle = (
    <Tooltip id="tooltip">
      <strong>Rastgele Kelime Getir</strong>
    </Tooltip>
  );

  return (
    <Sticky innerZ={20}>
      <Container
        fluid
        style={{
          backgroundColor: Colors.Blue,
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
        className="FixedHeader"
        id="FixedHeader"
      >
        <Row
        
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <Col xs={1}>
            <MenuSVG width={"3.75vw"} height={"5vh"} color={"#FFF"} />
          </Col> */}
          <Col>
            <Row>
              {/* <Image src={logo} style={{ width: "28vw", height: "7.5vh", cursor:'pointer',zIndex:10 }} onClick={()=>navigate('/s/')}  /> */}
              <div
                style={{ zIndex: 10, cursor: "pointer",maxWidth:'28vw' }}
                onClick={() => navigate("/s/")}
              >
                <KubbealtıLogoSVG
                  width={"25vw"}
                  height={"7vh"}
                  color={"white"}
                />
              </div>
            </Row>
          </Col>
          <Col style={{ padding: 0 }}>
            <Row >
              <Col
                xs={8}
                lg={9}
                style={{
                  padding: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                  
                }}
              >
                {/* <InputGroup
                  style={{ width: "26vw", height: "5.8vh", borderRadius: 15 }}
                >
                  <Form.Control
                    type="email"
                    placeholder="Kelime"
                    style={{
                      borderTopLeftRadius: 15,
                      borderBottomLeftRadius: 15,
                    }}
                  />
                  <InputGroup.Text
                    style={{
                      backgroundColor: Colors.LightBlue,
                      borderTopRightRadius: 15,
                      borderBottomRightRadius: 15,
                      cursor: "pointer",
                    }}
                  >
                    <SearchSvg width={"1.6vw"} height={"3vh"} color={"#FFF"} />
                  </InputGroup.Text>
                </InputGroup> */}
                <Search
                  word={word}
                  searchInText={searchInText}
                  setMainWord={setMainWord}
                  keyboard={keyboardVisual}
                  setKeyboard={setKeyboard}
                />
              </Col>

              <Col xs={4} lg={3} style={{padding:0,margin:0,display:'flex',justifyContent:'flex-end'}}>
                <div
                  aria-label="First group"
                  // style={{
                  //   width: "11.5vw",
                  //   height: "5.8vh",
                  //   backgroundColor: Colors.LightBlue,
                  //   flexDirection: "row",
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "space-around",
                  //   borderRadius: 15,
                  //   margin:0,
                  //   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                  // }}
                  className="ButtonContainer"
                >
                  <OverlayTrigger placement="bottom" overlay={tooltip}>
                    <Button
                      style={{ backgroundColor: Colors.LightBlue, border: 0 }}
                      onClick={searchInTextHandler}
                      // title={`Metin İçi Arama  (${
                      //   searchInText ? "Aktif" : "Pasif"
                      // })`}
                    >
                      <SearchinTextSvg
                        width={"1.7vw"}
                        height={"3vh"}
                        color={"#FFF"}
                        opacity={searchInText ? 1 : 0.5}
                      />
                    </Button>
                  </OverlayTrigger>
                  <Line />
                  <OverlayTrigger placement="bottom" overlay={tooltipKeyboard}>
                    <Button
                      style={{ backgroundColor: Colors.LightBlue, border: 0 }}
                      onClick={()=>{setKeyboard(!keyboardVisual)}}
                      // title="Klavye"
                    >
                      <KeyboardSvg
                        width={"1.7vw"}
                        height={"3vh"}
                        color={"#FFF"}
                      />
                    </Button>
                  </OverlayTrigger>
                  <Line />
                  <OverlayTrigger placement="bottom" overlay={tooltipShuffle}>
                    <Button
                      style={{ backgroundColor: Colors.LightBlue, border: 0 }}
                      onClick={() => {
                        suggestWord();
                      }}
                      // title="Rastgele Kelime Getir"
                    >
                      <ShuffleSvg
                        width={"1.3vw"}
                        height={"3vh"}
                        color={"#FFF"}
                      />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="Ayverdi" onClick={()=>navigate('/muellif-ve-lugat-hakkinda')}>İlhan Ayverdi</div>
      </Container>
      {
        // parse(require("../../keyboard/keyboard.html"))
      }
      {/* <div dangerouslySetInnerHTML={{_html:require("../../keyboard/keyboard.html")}}></div> */}
      {/* <Keyboard/> */}
      
    </Sticky>
  );
}

export default FixedHeader;
