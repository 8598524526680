import React, { useEffect, useState } from 'react';
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../axios/Axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Colors } from '../../assets/Colors';
import { SearchSvg } from '../../assets/svg';

import './mobileSearch.css';

let inputNumber=0;

function MobileSearch({word,searchInText}) {
    const navigate = useNavigate();
  
    const tooltipSearch = (
      <Tooltip id="tooltip">
        <strong>Ara</strong>
      </Tooltip>
    );
  
    const [selected, setSelected] = useState();
    const [value, setValue] = useState(word);
    const [suggestions, setSuggestion] = useState([]);
  
    const getValue = () => {
      return value;
    };
  
    const getSuggestion = async (word) => {
  
      if (word.length > 1) {
        const response = await axiosInstance.get(`word-search/${word}`);
        let _words = [];
        response.data.forEach((element) => {
          _words.push(element.display);
        });
        const _value = getValue();
        if (_value != word) {
          setSuggestion(_words);
        }
      } else {
        setSuggestion([]);
      }
    };
  
    const suggestionsHandler = async (input,n) => {
  
      if (n==input.length) {
        getSuggestion(input);
      }
  
    };
  
    const searchFunction = async () => {
      if (value[0]=="–" || value[0]=="-") {
        setValue(value.substring(1));
      }
      if (searchInText) { 
        navigate(`/e/${value.replace(/\s/g, '')}`);
      } else {
        navigate(`/s/${value.replace(/\s/g, '')}`);
  
      }
    };
  
    const selectSearch = async (selected) => {
      if (selected[0][0]=="–") {
        selected[0]=selected[0].substring(1);
      }
      navigate(`/s/${selected[0].replace(/\s/g, '')}`);
    };
  
    useEffect(()=>{
      getSuggestion(value)
    },[]);
  
    return (
      <Form.Group style={{width:'90%'}}>
        <InputGroup style={{ width: "100%", height: "5.8vh", borderRadius: 15 }}>
          <Typeahead
            
            id="Search"
            className="InputPropMobile"
            inputProps={{
              style:{
                borderRadius:0,
                borderTopLeftRadius:15,
                borderBottomLeftRadius:15
              }
            }}
            emptyLabel="Kelime Bulunamadı"
            onInputChange={async (input) => {
              setValue(input);
              inputNumber=input.length;
              setTimeout(() => suggestionsHandler(input,inputNumber), 300);
            }}
            onChange={(selected) => {
              setSelected(selected);
              if (selected.length > 0) {
                selectSearch(selected);
              }
            }}
            options={suggestions}
            selected={selected}
            defaultInputValue={word}
            placeholder="Kelime ..."
          />
          
            <InputGroup.Text
              style={{
                backgroundColor: Colors.LightBlue,
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
                cursor: "pointer",
                width:'15%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
              }}
              onClick={searchFunction}
            >
              <SearchSvg width={"25px"} height={"25px"} color={"#FFF"} />
            </InputGroup.Text>
          
        </InputGroup>
      </Form.Group>
    );
}

export default MobileSearch;