import React from "react";
import { Container } from "react-bootstrap";
import { Colors } from "../../assets/Colors";
import './secondDonationCard.css'
import { useNavigate } from "react-router-dom";

function SecondDonationCard(props) {
  const navigate=useNavigate();
  return (
    <div>
      <Container
        className="SecondDonationCard"
      >
        <text className="SecondDonationCardText">
          Bu hizmetlerin devam etmesi ve daha ileriye taşınabilmesi için
          katkılarınızı bekliyoruz.
        </text>
        <div className="SecondDonationCardButton" onClick={()=>navigate("/bagis")}>Bağış yapmak için tıklayın</div>
      </Container>
    </div>
  );
}

export default SecondDonationCard;
