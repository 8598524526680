import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Colors } from "../assets/Colors";
import Sticky from "react-stickynode";
import Space from "../components/Space/Space";
import DonationCard from "../components/DonationCard/DonationCard";
import Osmanlica from "../components/Osmanlica/Osmanlica";
import KitapReklam from "../components/KitapReklam/KitapReklam";

function LoadingPage(props) {
  return (
    <Container
      fluid
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        backgroundColor: Colors.White,
        alignContent: "stretch",
        // alignItems:'center',
        // height:"60vh"
      }}
      className="PageBody"
      id="HomePageBody"
    >
      <Row>
        <Col
          xs={12}
          lg={9}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 25,
          }}
        >
          <img src={require("../assets/img/gif2.gif")} style={{backgroundColor: 'transparent',width:25,height:25}}/>
          Yükleniyor
        </Col>
        <Col
          xs={12}
          lg={3}
          // style={{ position: "fixed" }}
          // style={{position:'fixed',}}
          // style={{position:'fixed',}}
          // style={{
          //   display: "flex",
          //   alignItems: "flex-end",
          //   flexDirection: "column",
          // }}
        >
          <Sticky
            top="#FixedHeader"
            bottomBoundary="#Footer"
            shouldFreeze={() => {
              if (window.screen.width <= 991) {
                return true;
              } else {
                return false;
              }
            }}
          >
            <Space top={"1vh"}></Space>
            <Row>
              <Col xs={12} lg={1}></Col>
              <Col xs={12} lg={11}>
                <KitapReklam/>
              </Col>
            </Row>
            <div style={{ height: 15 }}></div>
            <Row>
              <Col xs={12} lg={1}></Col>
              <Col xs={12} lg={11}>
                <Osmanlica/>
              </Col>
            </Row>
          </Sticky>
        </Col>
      </Row>
    </Container>
  );
}

export default LoadingPage;
