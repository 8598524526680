import React, { useRef, useState } from "react";
import "./contactForm.css";
import { Col, Container, Row, Form, Button, InputGroup } from "react-bootstrap";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import Space from "../Space/Space";
import { MailSvg, MessageIcon, PersonIcon } from "../../assets/svg";

// import FormData from "form-data";
import * as FormData from "form-data";
import Mailgun from "mailgun.js";
// import mailgun from "mailgun-js";

import emailjs from "@emailjs/browser";

function ContactForm(props) {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef(null);

  const sendMail = (e) => {
    // e.preventDefault();



    const mailgun = new Mailgun(FormData);
    const mg = mailgun.client({
      username: "api",
      key: "fbeff5588965ba042cd4c35c410739c3-2c441066-d7c567e0",
    });

    mg.messages.create('sandbox31cb9e6dd58e45ddb0198c6fd74daa64.mailgun.org', {
      from: `${form.current[0].value} <${form.current[1].value}>`,
      to: ["kubbealtibilgi@gmail.com","kubbealti.gelistirici@gmail.com","mga3018@gmail.com"],
      subject: form.current[2].value,
      text: form.current[3].value,
      // html: "<h1>Testing some Mailgun awesomness!</h1>"
    })
    .then(msg => console.log(msg)) 
    .catch(err => console.error(err)); 
    
  };

  return (
    <Container fluid style={{ padding: 0 }}>
      <text className="ContactFormTitle">İletişim Formu</text>

      <HorizontalLine />

      <Space top={"1vh"} />

      <Form
        ref={form}
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onSubmit={sendMail}
      >
        <Form.Group controlId="formBasicEmail" className="FormGroup">
          <Form.Label className="ContactFormSubTitle">
            Adınız Soyadınız
          </Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text className="MessageBoxColorPart">
              <PersonIcon width={"22px"} height={"22px"} color={"#FFF"} />
            </InputGroup.Text>
            <Form.Control
              name="name_from"
              type="name"
              placeholder="Adınız Soyadınız"
              className="Input"
            />
          </InputGroup>
        </Form.Group>

        <Space top={"1vh"} />
        <Form.Group className="FormGroup" controlId="formBasicEmail">
          <Form.Label className="ContactFormSubTitle">
            E-Posta Adresiniz
          </Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text className="MessageBoxColorPart">
              <MailSvg width={"22px"} height={"22px"} color={"#FFF"} />
            </InputGroup.Text>
            <Form.Control
              name="email_from"
              type="text"
              placeholder="E-Posta Adresiniz"
              className="Input"
            />
          </InputGroup>
        </Form.Group>

        <Space top={"1vh"} />
        <Form.Group className="FormGroup" controlId="formBasicPassword">
          <Form.Label className="ContactFormSubTitle">Konu</Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="SelectInput"
            name="title_from"
          >
            {/* <option>Konu</option> */}
            <option value="İhbar">İhbar</option>
            <option value="Bildirim">Bildirim</option>
            <option value="Şikayet">Şikayet</option>
            <option value="Öneri">Öneri</option>
          </Form.Select>
        </Form.Group>

        <Space top={"2vh"} />
        <Form.Group
          className="FormGroup"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label className="ContactFormSubTitle">Mesajınız</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text className="MessageBoxColorPart2">
              <MessageIcon width={"22px"} height={"22px"} color={"#FFF"} />
            </InputGroup.Text>
            <Form.Control
              name="message"
              as="textarea"
              aria-label="With textarea"
              className="Input"
              style={{ minHeight: 221 }}
            />
          </InputGroup>
        </Form.Group>

        <Space top={"2vh"} />
        <Button type="submit" className="ContactFormButton">
          Gönder
        </Button>
      </Form>
    </Container>
  );
}

export default ContactForm;
