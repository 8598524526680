import React, { useState } from "react";
import "./mobileHeader.css";
import Sticky from "react-stickynode";
import { Container, Dropdown, Image } from "react-bootstrap";
import { Colors } from "../../assets/Colors";
import { useNavigate } from "react-router-dom";
import Search from "../Search/Search";
import MobileSearch from "../MobileSearch/MobileSearch";
import CustomDropdown from "./Dropdown/Dropdown";
import { axiosInstance } from "../../axios/Axios";
import { KubbealtıLogoSVG, MenuSVG } from "../../assets/svg";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

function MobileHeader(props) {
  const suggestWord = async () => {
    let response;
    try {
      response = await axiosInstance.get(`suggest-word`);
      if (response.data.kelime != undefined) {
        navigate(`/s/${response.data.kelimeSiralama}`);
      } else {
        suggestWord();
      }
    } catch (error) {
      suggestWord();
    }
  };

  const [searchInText, setSearchInText] = useState(false);
  const navigate = useNavigate();

  return (
    <div >
      <BurgerMenu
        pageWrapId={"MobileHeader"}
        outerContainerId={"outer-container"}
      />
      <Sticky
        innerZ={20}
        className="MobileHeaderContainer"
        id="outer-container"
      >
        
        <Container
          fluid
          style={{
            backgroundColor: Colors.Blue,
            paddingLeft: "5vw",
            paddingRight: "5vw",
          }}
          className="MobileHeader"
          id="MobileHeader"
        >
          <div
            style={{ width: "80%", height: "7.5vh", cursor: "pointer",paddingLeft:"8vw" }}
            onClick={() => navigate("/s/")}
          >
            <KubbealtıLogoSVG width={"100%"} height={"7.5vh"} color={"white"} />
          </div>
          {/* <Image
          src={logo}
          style={{ width: "80%", height: "7.5vh", cursor: "pointer" }}
          onClick={() => navigate("/s/")}
        /> */}
          <div
            className="AyverdiMobile"
            onClick={() => {
              navigate("muellif-ve-lugat-hakkinda");
            }}
          >
            İlhan Ayverdi
          </div>
        </Container>
        <Container
          fluid
          className="SecondaryPartHeader"
          
        >
          <MobileSearch word={""} searchInText={searchInText} />
          <CustomDropdown
            setMainSearchInText={setSearchInText}
            suggestWord={suggestWord}
          />
        </Container>
      </Sticky>
    </div>
  );
}

export default MobileHeader;
