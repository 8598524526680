import React from "react";
import "./svgstyles.css";

function InfoSvg({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9844 7V4.98438H9.01562V7H10.9844ZM10.9844 15.0156V9.01562H9.01562V15.0156H10.9844ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875Z"
        fill={color}
      />
    </svg>
  );
}

function QuestionMarkSvg({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0469 9.25C13.6719 8.625 13.9844 7.875 13.9844 7C13.9844 5.90625 13.5938 4.96875 12.8125 4.1875C12.0312 3.40625 11.0938 3.01562 10 3.01562C8.90625 3.01562 7.96875 3.40625 7.1875 4.1875C6.40625 4.96875 6.01562 5.90625 6.01562 7H7.98438C7.98438 6.46875 8.1875 6 8.59375 5.59375C9 5.1875 9.46875 4.98438 10 4.98438C10.5312 4.98438 11 5.1875 11.4062 5.59375C11.8125 6 12.0156 6.46875 12.0156 7C12.0156 7.53125 11.8125 8 11.4062 8.40625L10.1875 9.67188C9.40625 10.5156 9.01562 11.4531 9.01562 12.4844V13H10.9844C10.9844 11.9688 11.375 11.0312 12.1562 10.1875L13.0469 9.25ZM10.9844 16.9844V15.0156H9.01562V16.9844H10.9844ZM2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875Z"
        fill={color}
      />
    </svg>
  );
}

function MailSvg({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0156 5.01562V3L10 8.01562L1.98438 3V5.01562L10 9.98438L18.0156 5.01562ZM18.0156 0.984375C18.5469 0.984375 19 1.1875 19.375 1.59375C19.7812 2 19.9844 2.46875 19.9844 3V15C19.9844 15.5312 19.7812 16 19.375 16.4062C19 16.8125 18.5469 17.0156 18.0156 17.0156H1.98438C1.45312 17.0156 0.984375 16.8125 0.578125 16.4062C0.203125 16 0.015625 15.5312 0.015625 15V3C0.015625 2.46875 0.203125 2 0.578125 1.59375C0.984375 1.1875 1.45312 0.984375 1.98438 0.984375H18.0156Z"
        fill={color}
      />
    </svg>
  );
}

function SearchSvg({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.4375 12.9375C5.60417 14.1042 7.02083 14.6875 8.6875 14.6875C10.3542 14.6875 11.7708 14.1042 12.9375 12.9375C14.1042 11.7708 14.6875 10.3542 14.6875 8.6875C14.6875 7.02083 14.1042 5.60417 12.9375 4.4375C11.7708 3.27083 10.3542 2.6875 8.6875 2.6875C7.02083 2.6875 5.60417 3.27083 4.4375 4.4375C3.27083 5.60417 2.6875 7.02083 2.6875 8.6875C2.6875 10.3542 3.27083 11.7708 4.4375 12.9375ZM16.6875 14.6875L23.3125 21.3125L21.3125 23.3125L14.6875 16.6875V15.625L14.3125 15.25C12.7292 16.625 10.8542 17.3125 8.6875 17.3125C6.27083 17.3125 4.20833 16.4792 2.5 14.8125C0.833333 13.1458 0 11.1042 0 8.6875C0 6.27083 0.833333 4.22917 2.5 2.5625C4.20833 0.854167 6.27083 0 8.6875 0C11.1042 0 13.1458 0.854167 14.8125 2.5625C16.4792 4.22917 17.3125 6.27083 17.3125 8.6875C17.3125 9.5625 17.1042 10.5625 16.6875 11.6875C16.2708 12.7708 15.7917 13.6458 15.25 14.3125L15.625 14.6875H16.6875Z"
        fill={color}
      />
    </svg>
  );
}

function SearchinTextSvg({ width, height, color, opacity }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3125 4H0.6875V1.3125H7.3125V4ZM7.3125 8H0.6875V10.6875H7.3125V8ZM25.4375 17.3125L20.375 12.25C19.8333 12.5833 19.25 12.8542 18.625 13.0625C18 13.2292 17.3542 13.3125 16.6875 13.3125C15.4375 13.3125 14.3125 13.0208 13.3125 12.4375C12.3125 11.8542 11.5 11.0625 10.875 10.0625C10.2917 9.02083 10 7.89583 10 6.6875C10 5.4375 10.2917 4.3125 10.875 3.3125C11.5 2.3125 12.3125 1.52083 13.3125 0.9375C14.3125 0.3125 15.4375 0 16.6875 0C17.8958 0 19 0.3125 20 0.9375C21.0417 1.52083 21.8542 2.3125 22.4375 3.3125C23.0208 4.3125 23.3125 5.4375 23.3125 6.6875C23.3125 7.35417 23.2083 8 23 8.625C22.8333 9.25 22.5833 9.8125 22.25 10.3125L27.3125 15.4375L25.4375 17.3125ZM20.6875 6.6875C20.6875 5.9375 20.5 5.27083 20.125 4.6875C19.75 4.0625 19.25 3.58333 18.625 3.25C18.0417 2.875 17.3958 2.6875 16.6875 2.6875C15.9375 2.6875 15.25 2.875 14.625 3.25C14.0417 3.58333 13.5625 4.0625 13.1875 4.6875C12.8542 5.27083 12.6875 5.9375 12.6875 6.6875C12.6875 7.39583 12.8542 8.0625 13.1875 8.6875C13.5625 9.27083 14.0417 9.75 14.625 10.125C15.25 10.5 15.9375 10.6875 16.6875 10.6875C17.3958 10.6875 18.0417 10.5 18.625 10.125C19.25 9.75 19.75 9.27083 20.125 8.6875C20.5 8.0625 20.6875 7.39583 20.6875 6.6875ZM0.6875 17.3125H14V14.6875H0.6875V17.3125Z"
        fill={color}
        fillOpacity={opacity}
        // fill-opacity={opacity}
      />
    </svg>
  );
}

function KeyboardSvg({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3125 7.3125V4.6875H20.6875V7.3125H23.3125ZM23.3125 11.3125V8.6875H20.6875V11.3125H23.3125ZM19.3125 7.3125V4.6875H16.6875V7.3125H19.3125ZM19.3125 11.3125V8.6875H16.6875V11.3125H19.3125ZM19.3125 16.6875V14H8.6875V16.6875H19.3125ZM7.3125 7.3125V4.6875H4.6875V7.3125H7.3125ZM7.3125 11.3125V8.6875H4.6875V11.3125H7.3125ZM8.6875 8.6875V11.3125H11.3125V8.6875H8.6875ZM8.6875 4.6875V7.3125H11.3125V4.6875H8.6875ZM12.6875 8.6875V11.3125H15.3125V8.6875H12.6875ZM12.6875 4.6875V7.3125H15.3125V4.6875H12.6875ZM24.6875 0.6875C25.3958 0.6875 26 0.958333 26.5 1.5C27.0417 2 27.3125 2.60417 27.3125 3.3125V16.6875C27.3125 17.3958 27.0417 18.0208 26.5 18.5625C26 19.0625 25.3958 19.3125 24.6875 19.3125H3.3125C2.60417 19.3125 1.97917 19.0625 1.4375 18.5625C0.9375 18.0208 0.6875 17.3958 0.6875 16.6875V3.3125C0.6875 2.60417 0.9375 2 1.4375 1.5C1.97917 0.958333 2.60417 0.6875 3.3125 0.6875H24.6875Z"
        fill={color}
      />
    </svg>
  );
}

function ShuffleSvg({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 12.875L18.9375 17.0625L21.6875 14.3125V21.6875H14.3125L17.0625 18.9375L12.875 14.75L14.75 12.875ZM14.3125 0.3125H21.6875V7.6875L18.9375 4.9375L2.1875 21.6875L0.3125 19.8125L17.0625 3.0625L14.3125 0.3125ZM9.125 7.25L7.25 9.125L0.3125 2.1875L2.1875 0.3125L9.125 7.25Z"
        fill={color}
      />
    </svg>
  );
}

function MenuSVG({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H54V6.04688H0V0ZM0 20.9531V15.0469H54V20.9531H0ZM0 36V29.9531H54V36H0Z"
        fill={color}
      />
    </svg>
  );
}

function ApppleIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3906 13.2109C17.3906 13.3203 17.2266 16.5469 20.7812 18.2422C20.125 20.2656 17.8281 24.75 15.1484 24.75C13.6172 24.75 12.7422 23.7656 10.9922 23.7656C9.1875 23.7656 8.20312 24.75 6.83594 24.75C4.21094 24.8594 1.69531 19.9375 0.984375 17.9141C0.4375 16.3828 0.21875 14.9062 0.21875 13.4844C0.21875 8.61719 3.44531 6.26562 6.50781 6.21094C7.98438 6.21094 9.84375 7.25 10.6641 7.25C11.4297 7.25 13.5625 5.99219 15.5312 6.15625C17.5547 6.32031 19.0859 7.08594 20.125 8.5625C18.3203 9.71094 17.3906 11.1875 17.3906 13.2109ZM14.3281 4.24219C13.2344 5.5 11.9219 6.21094 10.5 6.10156C10.3906 4.625 10.9375 3.25781 11.9219 2.16406C12.7969 1.17969 14.3281 0.359375 15.6406 0.25C15.6406 0.851562 15.8047 2.49219 14.3281 4.24219Z"
        fill={color}
      />
    </svg>
  );
}

function GooglePlayIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2344 11.0156L17.0469 8.20312L3.875 0.609375L14.2344 11.0156ZM1.20312 0L13.2031 12L1.20312 24C0.59375 23.7188 0.171875 23.1094 0.171875 22.3594V1.6875C0.171875 0.9375 0.59375 0.328125 1.20312 0ZM21.125 10.5938C22.0156 11.25 22.0156 12.7969 21.1719 13.4531L18.3594 15.0469L15.2656 12L18.3594 9L21.125 10.5938ZM3.875 23.3906L14.2344 13.0312L17.0469 15.8438L3.875 23.3906Z"
        fill="white"
      />
    </svg>
  );
}

function FacebookIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.375 0.75C30.2031 0.75 31.75 2.29688 31.75 4.125V28.875C31.75 30.7734 30.2031 32.25 28.375 32.25H18.6719V21.5625H22.75L23.5234 16.5H18.6719V13.2656C18.6719 11.8594 19.375 10.5234 21.5547 10.5234H23.7344V6.23438C23.7344 6.23438 21.7656 5.88281 19.7969 5.88281C15.8594 5.88281 13.2578 8.34375 13.2578 12.7031V16.5H8.82812V21.5625H13.2578V32.25H3.625C1.72656 32.25 0.25 30.7734 0.25 28.875V4.125C0.25 2.29688 1.72656 0.75 3.625 0.75H28.375Z"
        fill={color}
      />
    </svg>
  );
}

function TwitterIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.375 0.75C30.2031 0.75 31.75 2.29688 31.75 4.125V28.875C31.75 30.7734 30.2031 32.25 28.375 32.25H3.625C1.72656 32.25 0.25 30.7734 0.25 28.875V4.125C0.25 2.29688 1.72656 0.75 3.625 0.75H28.375ZM24.9297 11.9297C25.7734 11.2969 26.6172 10.4531 27.25 9.53906C26.4062 9.89062 25.4922 10.1719 24.5781 10.2422C25.4922 9.67969 26.2656 8.83594 26.6172 7.71094C25.7031 8.27344 24.7188 8.625 23.6641 8.83594C22.8203 7.92188 21.625 7.35938 20.2891 7.35938C17.4766 7.35938 15.1562 9.96094 15.7891 13.0547C11.9922 12.8438 8.54688 11.0156 6.29688 8.20312C5.875 8.90625 5.66406 9.67969 5.66406 10.5938C5.66406 12.1406 6.4375 13.5469 7.70312 14.3906C6.92969 14.3906 6.22656 14.1797 5.66406 13.8281V13.8984C5.66406 16.1484 7.21094 17.9766 9.32031 18.3984C8.61719 18.6094 7.98438 18.6094 7.21094 18.4688C7.84375 20.2969 9.53125 21.6328 11.5703 21.7031C9.95312 22.8984 7.98438 23.6719 5.80469 23.6719C5.45312 23.6719 5.10156 23.6719 4.75 23.6016C6.78906 24.9375 9.17969 25.6406 11.7812 25.6406C20.2891 25.6406 24.9297 18.6094 24.9297 12.5625C24.9297 12.3516 24.9297 12.1406 24.9297 11.9297Z"
        fill={color}
      />
    </svg>
  );
}

function InstagramIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12.7734C18.0391 12.7734 19.7266 14.4609 19.7266 16.5C19.7266 17.2031 19.5156 17.8359 19.2344 18.3984C17.7578 20.9297 14.1719 20.9297 12.6953 18.3984C11.2891 15.9375 13.0469 12.7734 16 12.7734ZM24.7188 9.89062C25.3516 11.3672 25.2109 14.8828 25.2109 16.5C25.2109 18.1875 25.3516 21.7031 24.7188 23.1797C24.3672 24.1641 23.5938 24.9375 22.6094 25.2891C21.1328 25.9219 17.6172 25.7812 16 25.7812C14.3125 25.7812 10.7969 25.9219 9.32031 25.2891C8.33594 24.9375 7.5625 24.1641 7.21094 23.1797C6.64844 21.7031 6.71875 18.1875 6.71875 16.5C6.71875 14.8828 6.64844 11.3672 7.21094 9.89062C7.5625 8.90625 8.33594 8.13281 9.32031 7.78125C10.7969 7.14844 14.3125 7.28906 16 7.28906C17.6172 7.28906 21.1328 7.14844 22.6094 7.78125C23.5938 8.13281 24.3672 8.90625 24.7188 9.89062ZM16 22.2656C19.1641 22.3359 21.7656 19.7344 21.7656 16.5C21.7656 15.5156 21.4844 14.5312 20.9922 13.6172C18.7422 9.82031 13.1875 9.82031 10.9375 13.6172C8.75781 17.4844 11.5 22.2656 16 22.2656ZM21.9766 11.8594C22.6797 11.8594 23.3125 11.2969 23.3125 10.5234C23.3125 10.3125 23.2422 10.0312 23.1016 9.82031C22.6094 8.97656 21.3438 8.97656 20.7812 9.82031C20.2891 10.7344 20.9219 11.8594 21.9766 11.8594ZM28.375 0.75C30.2031 0.75 31.75 2.29688 31.75 4.125V28.875C31.75 30.7734 30.2031 32.25 28.375 32.25H3.625C1.72656 32.25 0.25 30.7734 0.25 28.875V4.125C0.25 2.29688 1.72656 0.75 3.625 0.75H28.375ZM27.1094 21.1406C27.25 19.3125 27.25 13.7578 27.1094 11.9297C27.0391 10.1016 26.6172 8.48438 25.3516 7.21875C24.0156 5.88281 22.3984 5.46094 20.6406 5.39062C18.7422 5.25 13.1875 5.25 11.3594 5.39062C9.53125 5.46094 7.91406 5.88281 6.64844 7.14844C5.3125 8.48438 4.89062 10.1016 4.82031 11.8594C4.67969 13.7578 4.67969 19.3125 4.82031 21.1406C4.89062 22.9688 5.3125 24.5859 6.64844 25.8516C7.91406 27.1875 9.53125 27.6094 11.3594 27.6797C13.1875 27.8203 18.7422 27.8203 20.6406 27.6797C22.3984 27.6094 24.0156 27.1875 25.3516 25.8516C26.6172 24.5859 27.0391 22.9688 27.1094 21.1406Z"
        fill={color}
      />
    </svg>
  );
}

function PersonIcon({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9375 15.1875C6.39583 14.1875 8.75 13.6875 11 13.6875C13.25 13.6875 15.5833 14.1875 18 15.1875C20.4583 16.1458 21.6875 17.4167 21.6875 19V21.6875H0.3125V19C0.3125 17.4167 1.52083 16.1458 3.9375 15.1875ZM14.75 9.4375C13.7083 10.4792 12.4583 11 11 11C9.54167 11 8.29167 10.4792 7.25 9.4375C6.20833 8.39583 5.6875 7.14583 5.6875 5.6875C5.6875 4.22917 6.20833 2.97917 7.25 1.9375C8.29167 0.854167 9.54167 0.3125 11 0.3125C12.4583 0.3125 13.7083 0.854167 14.75 1.9375C15.7917 2.97917 16.3125 4.22917 16.3125 5.6875C16.3125 7.14583 15.7917 8.39583 14.75 9.4375Z"
        fill={color}
      />
    </svg>
  );
}

function MessageIcon({ width, height, color }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 8.6875V6H6V8.6875H22ZM16.6875 16.6875V14H6V16.6875H16.6875ZM6 10V12.6875H22V10H6ZM24.6875 0.6875C25.3958 0.6875 26 0.958333 26.5 1.5C27.0417 2 27.3125 2.60417 27.3125 3.3125V19.3125C27.3125 20.0208 27.0417 20.6458 26.5 21.1875C26 21.7292 25.3958 22 24.6875 22H6L0.6875 27.3125V3.3125C0.6875 2.60417 0.9375 2 1.4375 1.5C1.97917 0.958333 2.60417 0.6875 3.3125 0.6875H24.6875Z"
        fill="white"
      />
    </svg>
  );
}

function AudioIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill="#0F5868"
        d="M3 9v6h4l5 5V4L7 9zm13.5 3A4.5 4.5 0 0 0 14 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02M14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77"
      />
    </svg>
  );
}

function AudioIconFirst({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path fill="#0F5868" d="M3 9v6h4l5 5V4l-5 5z" />
    </svg>
  );
}

function AudioIconSecond({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill="#0F5868"
        // d="M18.5 12A4.5 4.5 0 0 0 16 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02M5 9v6h4l5 5V4L9 9z"
        d="M3 9v6h4l5 5V4L7 9zm13.5 3A4.5 4.5 0 0 0 14 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02M14 3.23v2.06c2.89.86"
      />
    </svg>
  );
}

function ThreeDotIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
    >
      <g fill={color}>
        <circle cx="10" cy="15" r="2" />
        <circle cx="10" cy="10" r="2" />
        <circle cx="10" cy="5" r="2" />
      </g>
    </svg>
  );
}

function KubbealtıLogoSVG({ width, height, color }) {
  return (
    <svg
      id="Layer_1"
      width={width}
      height={height}
      fill={color}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3063.9 416.93"
    >
      <defs></defs>
      <path
        class="LogoCls-2"
        d="M1214.24,350.09l87.51-200.14h-55.03v-39.71h99.35l101.53,239.85h-47.71l-18.33-45.05h-101.58l-18.28,45.05h-47.45M1296.01,265.31h69.88l-34.77-85.96-35.11,85.96Z"
      />
      <path
        class="LogoCls-2"
        d="M771.3,350.09v-101.92h-28.66v-42.08h28.66v-95.85h128.01c19.55,0,35.98,5.67,49.23,16.88,13.25,11.21,19.8,26.93,19.8,47.28,0,10.89-2.6,20.55-7.89,29.01-5.23,8.4-12.42,14.68-21.47,18.72,12.99,4.24,23.37,11.34,31.08,21.33,7.7,10.04,11.65,22.42,11.65,37.09s-3.18,26.23-9.61,36.78c-6.37,10.52-15.16,18.6-26.37,24.28-11.2,5.6-23.75,8.47-37.7,8.47h-136.74M815.63,248.16v59.87h87.51c9.87,0,18.02-2.89,24.52-8.49,6.49-5.73,9.8-12.81,9.8-21.27s-3.31-15.82-9.75-21.56c-6.43-5.69-14.65-8.54-24.58-8.54h-87.51ZM815.63,152.3v53.78h78.65c9.37,0,16.69-2.55,21.91-7.77,5.29-5.18,7.89-11.65,7.89-19.36s-2.61-13.76-7.89-18.91c-5.29-5.17-12.61-7.75-21.91-7.75h-78.65Z"
      />
      <path
        class="LogoCls-2"
        d="M519.37,350.09v-101.92h-28.73v-42.08h28.73v-95.85h127.94c19.55,0,35.98,5.67,49.23,16.88,13.25,11.21,19.87,26.93,19.87,47.28,0,10.89-2.67,20.55-7.96,29.01-5.22,8.4-12.36,14.68-21.47,18.72,12.99,4.24,23.38,11.34,31.08,21.33,7.77,10.04,11.66,22.42,11.66,37.09s-3.18,26.23-9.56,36.78c-6.43,10.52-15.16,18.6-26.43,24.28-11.15,5.6-23.76,8.47-37.71,8.47h-136.66M563.69,152.3v53.78h78.59c9.36,0,16.68-2.55,21.9-7.77,5.28-5.18,7.9-11.65,7.9-19.36s-2.62-13.76-7.9-18.91c-5.28-5.17-12.61-7.75-21.9-7.75h-78.59ZM563.69,248.16v59.87h87.44c9.88,0,18.09-2.89,24.52-8.49,6.56-5.73,9.8-12.81,9.8-21.27s-3.25-15.82-9.74-21.56c-6.44-5.69-14.65-8.54-24.59-8.54h-87.44Z"
      />
      <rect
        class="LogoCls-1"
        x="1864.12"
        y="110.27"
        width="44.26"
        height="239.82"
      />
      <polyline
        class="LogoCls-1"
        points="1711.38 350.09 1711.38 152.3 1641.2 152.3 1641.2 110.24 1826.01 110.24 1826.01 152.3 1755.7 152.3 1755.7 350.09 1711.38 350.09"
      />
      <polyline
        class="LogoCls-1"
        points="1474.28 350.09 1474.28 110.24 1518.54 110.24 1518.54 308.03 1612.85 308.03 1612.85 250.77 1657.12 250.77 1657.12 350.09 1474.28 350.09"
      />
      <polyline
        class="LogoCls-1"
        points="1021.08 350.09 1021.08 248.16 992.43 248.16 992.43 206.09 1021.08 206.09 1021.08 110.24 1205.2 110.24 1205.2 152.3 1065.41 152.3 1065.41 206.09 1162.79 206.09 1162.79 248.16 1065.41 248.16 1065.41 308.03 1205.2 308.03 1205.2 350.09 1021.08 350.09"
      />
      <path
        class="LogoCls-1"
        d="M249.78,252.27V110.24h44.26v132.49c0,14.24,2.74,26.68,8.21,37.33,5.54,10.6,13.06,18.75,22.55,24.39,9.55,5.67,20,8.53,31.53,8.53s22.21-2.86,31.64-8.53c9.49-5.64,16.95-13.79,22.35-24.39,5.48-10.71,8.22-23.16,8.22-37.33V110.24h44.26v142.03c0,13.81-2.55,26.99-7.65,39.44-5.03,12.33-12.28,23.36-21.84,32.9-9.43,9.48-20.77,17-33.88,22.35-13.12,5.35-27.58,8.04-43.11,8.04s-29.94-2.69-43.19-8.09c-13.25-5.4-24.58-12.93-34.13-22.52-9.48-9.58-16.81-20.6-21.84-33.04-4.97-12.45-7.39-25.45-7.39-39.07"
      />
      <polyline
        class="LogoCls-1"
        points="28.66 350.09 28.66 248.16 0 248.16 0 206.09 28.66 206.09 28.66 110.24 72.92 110.24 72.92 206.09 89.35 206.09 169.92 110.24 225.33 110.24 132.98 216.09 228.82 350.09 172.27 350.09 102.34 248.16 72.92 248.16 72.92 350.09 28.66 350.09"
      />
      <path
        class="LogoCls-2"
        d="M2565.93,350.16l87.44-200.14h-55.01v-39.71h99.26l101.58,239.85h-47.76l-18.28-45.07h-101.65l-18.28,45.07h-47.3M2647.58,265.38h69.92l-34.77-85.95-35.15,85.95Z"
      />
      <polyline
        class="LogoCls-1"
        points="1954.72 416.93 1954.72 0 1998.98 0 1998.98 374.86 2274.76 374.86 2274.76 317.59 2319.01 317.59 2319.01 416.93 1954.72 416.93"
      />
      <rect
        class="LogoCls-1"
        x="3019.63"
        y="110.33"
        width="44.26"
        height="239.82"
      />
      <polyline
        class="LogoCls-1"
        points="2869.15 350.16 2869.15 152.37 2798.94 152.37 2798.94 110.3 2983.77 110.3 2983.77 152.37 2913.47 152.37 2913.47 350.16 2869.15 350.16"
      />
      <path
        class="LogoCls-1"
        d="M2296.6,232.32c0-23.01,5.67-44.03,17.06-63.14,11.39-19.17,27-34.23,46.7-45.25,19.73-11.02,41.38-16.56,64.89-16.56,19.73,0,38.52,4.1,56.36,12.39,17.7,8.2,33.13,20.53,46.04,36.9l-36.68,27.51c-15.99-23.15-37.88-34.77-65.72-34.77-14.08,0-27.51,3.73-40.32,11.11-12.61,7.29-22.8,17.35-30.37,30-7.58,12.7-11.36,26.59-11.36,41.8s3.78,29.09,11.36,41.73c7.57,12.68,17.77,22.63,30.37,30.05,12.8,7.33,26.24,11.02,40.32,11.02,20.17,0,37.56-5.79,52.21-17.26,14.78-11.5,24.47-27.32,29.17-47.55h-81.76v-42.06h129.22v31.67c0,10.78-2.56,22.83-7.54,36.21-4.88,13.3-12.66,26.14-23.23,38.59-10.51,12.45-24.02,22.63-40.44,30.61-16.48,7.86-35.73,11.77-57.63,11.77-23.57,0-45.23-5.47-64.89-16.49-19.63-10.95-35.24-26-46.64-45.13-11.39-19.1-17.12-40.18-17.12-63.18"
      />
      <path
        class="LogoCls-1"
        d="M2047.64,252.93V110.94h44.26v132.47c0,14.26,2.74,26.65,8.21,37.29,5.62,10.64,13.06,18.79,22.62,24.44,9.49,5.6,20,8.47,31.52,8.47s22.29-2.87,31.73-8.47c9.42-5.66,16.81-13.8,22.22-24.44,5.47-10.7,8.2-23.09,8.2-37.29V110.94h44.33v141.99c0,13.88-2.55,26.98-7.64,39.45-5.04,12.39-12.35,23.34-21.84,32.93-9.44,9.45-20.78,16.92-33.96,22.35-13.12,5.35-27.43,8.01-43.05,8.01s-29.92-2.71-43.23-8.15c-13.18-5.4-24.6-12.82-34.07-22.41-9.56-9.65-16.78-20.67-21.81-33.12-5.01-12.49-7.5-25.45-7.5-39.07"
      />
    </svg>
  );
}

function YılSvg({ width, height, color }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1917.01 1420.26"
      width={width}
      height={height}
      fill={color}
    >
      <defs></defs>
      <path
        class="cls-5"
        d="M90.99,892.48l78.7,73.75c12.87,12.2,23.86,20.51,32.99,24.91,9.14,4.37,18.24,6.88,27.4,7.47v5.47h-101.49v-5.47c6.07,0,10.43-.97,13.04-2.85,2.74-1.99,4.1-4.12,4.1-6.58,0-2.31-.53-4.45-1.51-6.32-.98-1.86-4.28-5.4-9.83-10.48l-73.6-68.8v64.92c0,10.12.65,16.82,2,20.12,1.1,2.52,3.28,4.58,6.65,6.37,4.51,2.39,9.28,3.62,14.32,3.62h7.24v5.47H0v-5.47h7.59c8.82,0,15.18-2.52,19.18-7.29,2.55-3.15,3.83-10.77,3.83-22.82v-130.06c0-10.19-.71-17.02-2.09-20.36-1.02-2.31-3.19-4.45-6.44-6.2-4.62-2.39-9.42-3.61-14.48-3.61H0v-5.42h90.99v5.42h-7.24c-4.95,0-9.7,1.16-14.32,3.43-3.28,1.6-5.48,3.98-6.78,7.13-1.25,3.17-1.87,9.67-1.87,19.6v61.53c2.06-1.81,9.27-8.05,21.56-18.78,31.12-26.88,49.98-44.87,56.47-53.89,2.81-3.95,4.23-7.42,4.23-10.38,0-2.27-1.08-4.25-3.31-5.92-2.21-1.81-5.9-2.72-11.15-2.72h-4.85v-5.42h78.34v5.42c-4.63.05-8.85.71-12.59,1.79-3.78,1.1-8.38,3.23-13.86,6.4-5.45,3.03-12.16,8.04-20.1,14.95-2.34,1.99-12.98,12.12-31.98,30.5l-32.55,30.55Z"
      />
      <path
        class="cls-5"
        d="M389.64,808.28v-5.42h75.45v5.42h-8.01c-8.35,0-14.82,3.35-19.34,9.99-2.2,3.03-3.27,10.11-3.27,21.33v81.63c0,20.23-2.18,35.86-6.46,47.07-4.24,11.08-12.57,20.62-25.04,28.69-12.35,7.93-29.24,11.79-50.63,11.79-23.28,0-40.99-3.79-53.05-11.4-12.07-7.62-20.6-17.87-25.64-30.7-3.32-8.88-5.04-25.39-5.04-49.59v-78.66c0-12.37-1.83-20.44-5.52-24.29-3.57-3.95-9.38-5.87-17.43-5.87h-8.06v-5.42h92.17v5.42h-8.15c-8.78,0-15.11,2.63-18.86,7.86-2.63,3.54-3.95,11.03-3.95,22.3v87.7c0,7.8.76,16.76,2.18,26.88,1.62,10.14,4.38,17.99,8.35,23.6,4,5.61,9.74,10.34,17.18,13.99,7.55,3.61,16.77,5.44,27.69,5.44,13.93,0,26.45-2.85,37.44-8.53,11.03-5.81,18.53-13.17,22.47-22.04,4.1-9.04,6.19-24.18,6.19-45.53v-81.5c0-12.57-1.54-20.44-4.43-23.66-4.1-4.32-10.14-6.5-18.27-6.5h-7.97Z"
      />
      <path
        class="cls-5"
        d="M637.34,901.38c14.77,2.98,25.86,7.68,33.14,14.25,10.25,9.09,15.32,20.25,15.32,33.4,0,9.95-3.32,19.54-10.06,28.76-6.74,9.17-15.96,15.87-27.69,20.06-11.71,4.19-29.54,6.24-53.49,6.24h-100.7v-5.47h8c8.95,0,15.31-2.7,19.18-8.01,2.43-3.48,3.63-10.88,3.66-22.11v-130.06c0-12.44-1.54-20.36-4.59-23.6-4.1-4.38-10.17-6.57-18.25-6.57h-8v-5.42h92.17c17.2,0,31.01,1.15,41.39,3.48,15.73,3.61,27.76,9.93,36.04,19.02,8.28,9.03,12.4,19.41,12.4,31.21,0,10.04-3.25,19.13-9.72,27.14-6.52,7.93-16.17,13.81-28.8,17.66M554.91,893.31c3.87.79,8.28,1.3,13.21,1.68,5.04.32,10.55.45,16.51.45,15.36,0,26.8-1.54,34.48-4.56,7.77-3.23,13.67-8.01,17.73-14.46,4.12-6.44,6.19-13.41,6.19-21.07,0-11.81-5.13-21.8-15.28-30.12-10.18-8.31-24.98-12.5-44.5-12.5-10.5,0-19.98,1.08-28.32,3.3v77.28ZM554.91,989.59c12.15,2.57,24.16,3.93,36.01,3.93,18.95,0,33.46-3.93,43.39-12,10.03-8.12,14.97-18.12,14.97-29.99,0-7.8-2.23-15.33-6.78-22.55-4.51-7.23-11.83-12.91-21.99-17.1-10.22-4.19-22.76-6.26-37.78-6.26-6.46,0-12.07.15-16.7.32-4.58.19-8.31.52-11.12,1.1v82.54Z"
      />
      <path
        class="cls-5"
        d="M864.56,901.38c14.77,2.98,25.86,7.68,33.21,14.25,10.16,9.09,15.25,20.25,15.25,33.4,0,9.95-3.36,19.54-10.09,28.76-6.7,9.17-15.92,15.87-27.69,20.06-11.61,4.19-29.44,6.24-53.49,6.24h-100.68v-5.47h8.03c8.9,0,15.31-2.7,19.21-8.01,2.39-3.48,3.62-10.88,3.62-22.11v-130.06c0-12.44-1.54-20.36-4.58-23.6-4.07-4.38-10.19-6.57-18.24-6.57h-8.03v-5.42h92.17c17.24,0,31.01,1.15,41.39,3.48,15.77,3.61,27.73,9.93,36.04,19.02,8.26,9.03,12.38,19.41,12.38,31.21,0,10.04-3.23,19.13-9.74,27.14-6.45,7.93-16.05,13.81-28.76,17.66M782.09,893.31c3.87.79,8.32,1.3,13.22,1.68,5.05.32,10.57.45,16.53.45,15.32,0,26.8-1.54,34.47-4.56,7.77-3.23,13.68-8.01,17.76-14.46,4.1-6.44,6.16-13.41,6.16-21.07,0-11.81-5.12-21.8-15.28-30.12-10.19-8.31-25.02-12.5-44.53-12.5-10.48,0-19.95,1.08-28.34,3.3v77.28ZM782.09,989.59c12.2,2.57,24.18,3.93,36.04,3.93,19.02,0,33.43-3.93,43.4-12,10-8.12,14.95-18.12,14.95-29.99,0-7.8-2.26-15.33-6.76-22.55-4.49-7.23-11.87-12.91-22.03-17.1-10.12-4.19-22.72-6.26-37.74-6.26-6.49,0-12.1.15-16.71.32-4.57.19-8.31.52-11.15,1.1v82.54Z"
      />
      <path
        class="cls-5"
        d="M1014.07,813.76v79.54h46.89c12.11,0,20.28-1.67,24.36-5.14,5.47-4.53,8.49-12.57,9.14-24.05h5.84v70.02h-5.84c-1.46-9.74-2.94-16.06-4.35-18.83-1.91-3.41-5-6.18-9.35-8.19-4.25-1.92-10.9-2.96-19.8-2.96h-46.89v66.41c0,8.89.4,14.3,1.23,16.3.87,1.88,2.33,3.43,4.45,4.58,2.07,1.12,6.07,1.68,11.94,1.68h36.17c12.1,0,20.83-.84,26.32-2.44,5.48-1.55,10.69-4.66,15.71-9.35,6.5-6.07,13.17-15.37,20.01-27.74h6.29l-18.37,50.48h-164.45v-5.47h7.55c5,0,9.83-1.18,14.31-3.41,3.36-1.62,5.61-4.01,6.81-7.17,1.23-3.17,1.9-9.67,1.9-19.47v-130.75c0-12.7-1.42-20.57-4.12-23.6-3.74-3.93-10.06-5.94-18.89-5.94h-7.55v-5.48h164.45l2.35,44.11h-6.2c-2.18-10.58-4.62-17.86-7.34-21.79-2.64-3.95-6.6-7.04-11.84-9.09-4.19-1.5-11.56-2.26-22.14-2.26h-58.55Z"
      />
      <path
        class="cls-5"
        d="M1310.87,936.65h-82.59l-14.44,31.85c-3.61,7.81-5.39,13.6-5.39,17.47,0,3.09,1.52,5.81,4.54,8.2,3.19,2.25,9.85,3.74,20.14,4.38v5.53h-67.14v-5.53c8.9-1.49,14.65-3.35,17.29-5.74,5.39-4.77,11.31-14.44,17.78-28.94l75.05-165.66h5.55l74.21,167.45c6.05,13.41,11.35,22.18,16.22,26.24,4.93,3.93,11.77,6.19,20.44,6.65v5.53h-84.17v-5.53c8.51-.32,14.17-1.68,17.14-3.93,3.09-2.39,4.58-5.11,4.58-8.38,0-4.32-2.12-11.18-6.31-20.57l-12.91-29.02ZM1306.49,925.69l-36.18-81.31-37.17,81.31h73.35Z"
      />
      <path
        class="cls-5"
        d="M1593.18,948.46l5.13,1.02-18.2,54.6h-165.21v-5.47h8.01c9.06,0,15.46-2.85,19.39-8.31,2.23-3.23,3.25-10.51,3.25-22v-129.86c0-12.57-1.42-20.49-4.42-23.6-4.01-4.38-10.11-6.57-18.23-6.57h-8.01v-5.48h96.63v5.48c-11.35-.13-19.36.9-23.92,3.02-4.53,2.07-7.62,4.72-9.3,7.81-1.7,3.2-2.51,10.77-2.51,22.76v126.43c0,8.19.81,13.88,2.51,16.9,1.28,2.12,3.23,3.67,5.82,4.56,2.6,1.04,10.79,1.55,24.55,1.55h15.54c16.45,0,27.9-1.15,34.5-3.41,6.57-2.33,12.63-6.26,18.08-12.05,5.45-5.82,10.89-15.03,16.37-27.4"
      />
      <path
        class="cls-5"
        d="M1781.2,802.86l2.41,47.13h-5.98c-1.17-8.33-2.72-14.25-4.76-17.79-3.25-5.74-7.57-9.93-13.05-12.57-5.3-2.85-12.44-4.21-21.25-4.21h-30.07v153.79c0,12.39,1.42,20.06,4.25,23.14,4.01,4.19,10.12,6.26,18.46,6.26h7.36v5.47h-90.49v-5.47h7.59c9.07,0,15.45-2.57,19.23-7.75,2.28-3.15,3.43-10.37,3.43-21.66v-153.79h-25.59c-10,0-17.1.65-21.29,2.07-5.48,1.88-10.11,5.48-14.02,10.84-3.83,5.34-6.15,12.57-6.89,21.66h-5.97l2.46-47.13h174.18Z"
      />
      <path
        class="cls-5"
        d="M1910.95,998.62v5.47h-91.42v-5.47h7.54c8.85,0,15.22-2.44,19.23-7.29,2.46-3.22,3.75-10.77,3.75-22.82v-130.06c0-10.24-.68-16.97-2.02-20.17-1.07-2.51-3.23-4.64-6.45-6.39-4.64-2.39-9.45-3.61-14.51-3.61h-7.54v-5.48h91.42v5.48h-7.7c-8.65,0-15.06,2.44-19.04,7.28-2.67,3.15-3.95,10.82-3.95,22.89v130.06c0,10.12.71,16.9,2.07,20.12,1.04,2.44,3.22,4.58,6.57,6.37,4.51,2.39,9.3,3.62,14.35,3.62h7.7Z"
      />
      <path
        class="cls-4"
        d="M967.66,0c6.45,0,160.28,70.08,187.82,79.5,83.82,28.74,173.99,29.58,256.04,65.1,177.72,76.81,495.06,399.85,498.06,617.07l-322.03-.13c-49.99-330.7-181.44-530.06-484.1-635.04-43.95-15.23-89.31-31.41-135.92-31.41s-92.07,16.13-135.99,31.33c-302.75,104.79-435.2,304.22-485.84,634.86l-322.01-.13c3.43-217.17,321.8-540,499.65-616.73,82.14-35.52,172.31-36.31,256.23-65C807.17,69.95,961.08,0,967.66,0Z"
      />
      <line class="cls-1" x1="23.7" y1="1135.93" x2="648.6" y2="1135.93" />
      <line class="cls-1" x1="23.7" y1="1349.43" x2="648.6" y2="1349.43" />
      <text class="cls-3" transform="translate(193.6 1288.4)">
        <tspan x="0" y="0">
          1970
        </tspan>
      </text>
      <text class="cls-6" transform="translate(856.6 1227.9)">
        <tspan x="0" y="0">
          5
        </tspan>
        <tspan class="cls-7" x="92.34" y="0">
          4
        </tspan>
        <tspan class="cls-8" x="189.1" y="0">
          .
        </tspan>
      </text>
      <text class="cls-2" transform="translate(829.43 1369.56)">
        <tspan x="0" y="0">
          YIL
        </tspan>
      </text>
      <line class="cls-1" x1="1292.12" y1="1135.93" x2="1917.01" y2="1135.93" />
      <line class="cls-1" x1="1292.12" y1="1349.43" x2="1917.01" y2="1349.43" />
      <text class="cls-3" transform="translate(1462.01 1288.4)">
        <tspan x="0" y="0">
          2024
        </tspan>
      </text>
    </svg>
  );
}

function FacebookIconSvg({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill="#3d5a98"
        d="M20.9 2H3.1A1.1 1.1 0 0 0 2 3.1v17.8A1.1 1.1 0 0 0 3.1 22h9.58v-7.75h-2.6v-3h2.6V9a3.64 3.64 0 0 1 3.88-4a20.26 20.26 0 0 1 2.33.12v2.7H17.3c-1.26 0-1.5.6-1.5 1.47v1.93h3l-.39 3H15.8V22h5.1a1.1 1.1 0 0 0 1.1-1.1V3.1A1.1 1.1 0 0 0 20.9 2"
      />
    </svg>
  );
}

function XIconSvg({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm297.1 84L257.3 234.6L379.4 396h-95.6L209 298.1L123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5l78.2-89.5zm-37.8 251.6L153.4 142.9h-28.3l171.8 224.7h26.3z"
      />
    </svg>
  );
}

function WhatsappIconSvg({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 448 512"
    >
      <path
        fill="#25D366"
        d="M224 122.8c-72.7 0-131.8 59.1-131.9 131.8c0 24.9 7 49.2 20.2 70.1l3.1 5l-13.3 48.6l49.9-13.1l4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8c0-35.2-15.2-68.3-40.1-93.2c-25-25-58-38.7-93.2-38.7m77.5 188.4c-3.3 9.3-19.1 17.7-26.7 18.8c-12.6 1.9-22.4.9-47.5-9.9c-39.7-17.2-65.7-57.2-67.7-59.8c-2-2.6-16.2-21.5-16.2-41s10.2-29.1 13.9-33.1c3.6-4 7.9-5 10.6-5c2.6 0 5.3 0 7.6.1c2.4.1 5.7-.9 8.9 6.8c3.3 7.9 11.2 27.4 12.2 29.4s1.7 4.3.3 6.9c-7.6 15.2-15.7 14.6-11.6 21.6c15.3 26.3 30.6 35.4 53.9 47.1c4 2 6.3 1.7 8.6-1c2.3-2.6 9.9-11.6 12.5-15.5c2.6-4 5.3-3.3 8.9-2c3.6 1.3 23.1 10.9 27.1 12.9s6.6 3 7.6 4.6c.9 1.9.9 9.9-2.4 19.1M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48M223.9 413.2c-26.6 0-52.7-6.7-75.8-19.3L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5c29.9 30 47.9 69.8 47.9 112.2c0 87.4-72.7 158.5-160.1 158.5"
      />
    </svg>
  );
}

function HomeIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 576 512"
    >
      <path
        fill="#ffffff"
        d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
      />
    </svg>
  );
}

function MenuInfoIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 512 512"
    >
      <path
        fill="#ffffff"
        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
      />
    </svg>
  );
}

function MenuMailIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 512 512"
    >
      <path
        fill="#ffffff"
        d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
      />
    </svg>
  );
}

function MenuFeatherIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 512 512"
    >
      <path
        fill="#ffffff"
        d="M278.5 215.6L23 471c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l57-57h68c49.7 0 97.9-14.4 139-41c11.1-7.2 5.5-23-7.8-23c-5.1 0-9.2-4.1-9.2-9.2c0-4.1 2.7-7.6 6.5-8.8l81-24.3c2.5-.8 4.8-2.1 6.7-4l22.4-22.4c10.1-10.1 2.9-27.3-11.3-27.3l-32.2 0c-5.1 0-9.2-4.1-9.2-9.2c0-4.1 2.7-7.6 6.5-8.8l112-33.6c4-1.2 7.4-3.9 9.3-7.7C506.4 207.6 512 184.1 512 160c0-41-16.3-80.3-45.3-109.3l-5.5-5.5C432.3 16.3 393 0 352 0s-80.3 16.3-109.3 45.3L139 149C91 197 64 262.1 64 330v55.3L253.6 195.8c6.2-6.2 16.4-6.2 22.6 0c5.4 5.4 6.1 13.6 2.2 19.8z"
      />
    </svg>
  );
}

function DonationIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M16 12c2.76 0 5-2.24 5-5s-2.24-5-5-5s-5 2.24-5 5s2.24 5 5 5m5.45 5.6c-.39-.4-.88-.6-1.45-.6h-7l-2.08-.73l.33-.94L13 16h2.8c.35 0 .63-.14.86-.37s.34-.51.34-.82c0-.54-.26-.91-.78-1.12L8.95 11H7v9l7 2l8.03-3c.01-.53-.19-1-.58-1.4M5 11H.984v11H5z"
      />
    </svg>
  );
}

function SecurityIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
        <path
          fill={color}
          d="M12 2v6.5a1.5 1.5 0 0 0 1.356 1.493L13.5 10H20v4.892l-2.367-.79a2 2 0 0 0-1.088-.05l-.178.05l-3 1a2 2 0 0 0-1.36 1.738L12 17v1.671a5.33 5.33 0 0 0 1.01 3.129l.152.2H6a2 2 0 0 1-1.995-1.85L4 20V4a2 2 0 0 1 1.85-1.995L6 2zm5 14l3 1v1.671a3.346 3.346 0 0 1-2.977 3.326h-.046a3.347 3.347 0 0 1-2.972-3.144L14 18.671V17zM14 2.043a2 2 0 0 1 .877.43l.123.113L19.414 7a2 2 0 0 1 .502.84l.04.16H14z"
        />
      </g>
    </svg>
  );
}

function CopyIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M20 2H10a2 2 0 0 0-2 2v2h8a2 2 0 0 1 2 2v8h2a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2"
      />
      <path
        fill={color}
        d="M4 22h10c1.103 0 2-.897 2-2V10c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2m2-10h6v2H6zm0 4h6v2H6z"
      />
    </svg>
  );
}

function NextIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path
        fill={color}
        d="M2 16A14 14 0 1 0 16 2A14 14 0 0 0 2 16m6-1h12.15l-5.58-5.607L16 8l8 8l-8 8l-1.43-1.427L20.15 17H8Z"
      />
      <path
        fill="none"
        d="m16 8l-1.43 1.393L20.15 15H8v2h12.15l-5.58 5.573L16 24l8-8z"
      />
    </svg>
  );
}

function PrevIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
    >
      <path
        fill={color}
        d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m8 15H11.85l5.58 5.573L16 24l-8-8l8-8l1.43 1.393L11.85 15H24Z"
      />
      <path
        fill="none"
        d="m16 8l1.43 1.393L11.85 15H24v2H11.85l5.58 5.573L16 24l-8-8z"
      />
    </svg>
  );
}

export {
  InfoSvg,
  QuestionMarkSvg,
  MailSvg,
  SearchSvg,
  SearchinTextSvg,
  KeyboardSvg,
  ShuffleSvg,
  MenuSVG,
  ApppleIcon,
  GooglePlayIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  PersonIcon,
  MessageIcon,
  AudioIcon,
  ThreeDotIcon,
  AudioIconFirst,
  AudioIconSecond,
  KubbealtıLogoSVG,
  YılSvg,
  FacebookIconSvg,
  XIconSvg,
  WhatsappIconSvg,
  HomeIcon,
  MenuInfoIcon,
  MenuMailIcon,
  MenuFeatherIcon,
  DonationIcon,
  SecurityIcon,
  CopyIcon,
  NextIcon,
  PrevIcon,
};
