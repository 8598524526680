import React from "react";
import "../texts.css";

function ÖdüllerText(props) {
  return (
    <div id="Ödüller">
      <text className="Title" id="OdullerTitle">
        Ödüller
      </text>

      <p className="Paragraph">
        <br />
        İlhan Ayverdi'ye Takdîm Edilen Ödüller:
        <br />
        <text >
          1. Kubbealtı Akademisi Kültür ve Sanat Vakfı Mensupları (2005)
        </text>
        <br />
        <text >
          2. Türk Kadınları Kültür Derneği Manisa Şubesi (2006)
        </text>
        <br />
        <text >
          3. Türk Kadınları Kültür Derneği Genel Merkezi (2006)
        </text>
        <br />
        <text >
          4. Altay Kültür, Sanat, Eğitim Vakfı (2006)
        </text>
        <br />
        <text >
          5. Türkiye Yazarlar Birliği "Yılın Yazarı Ödülü" (2005)
        </text>
        <br />
        <text >6. Manisa Aydınlar Ocağı</text>
        <br />
        <text >
          7. 731. Karaman Türk Dil Bayramı ve Yunus Emre'yi Anma Törenleri’nde,
          "Türk Diline Hizmet Özel Ödülü" (2007)
        </text>
        <br />
        <text >
          8. Elginkan Vakfı "Türk Kültürüne Hizmet Ödülü" (2009)
        </text>
        <br />
        <text >
          9. Karaman Belediyesi "Türk Dil Bayramı"nda "Hizmet Ödülü" (2010)
        </text>
      </p>
    </div>
  );
}

export default ÖdüllerText;
