import React from 'react';
import './footerMenu.css'
import { useNavigate } from 'react-router-dom';

function FooterMenu(props) {
    const navigate=useNavigate();
    return (
        <div className='FooterMenu'>
            <div style={{height:"100%",display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <p className='FooterMenuItem' onClick={()=>{navigate("/iletisim")}}>İletişim</p>
                <p className='FooterMenuItem'>Sözlük Kullanımı</p>
                <p className='FooterMenuItem' onClick={()=>{navigate("/gizlilik-politikasi")}}>Gizlilik Politikası</p>
                <p className='FooterMenuItem' onClick={()=>{navigate("/bagis")}}>Bağış</p>
            </div>
        </div>
    );
}

export default FooterMenu;