import React from "react";
import "./addressCard.css";

function AddressCard({ title, address, phone, faks }) {
  return (
    <div>
      <p className="AddressCardTitle">{title}</p>
      <p className="AddressCardSecondaryTitle">
        Adres : <text className="AddressCardValues">{address}</text>
      </p>
      <p className="AddressCardSecondaryTitle">
        Telefon : <text className="AddressCardValues">{phone}</text>
      </p>
      {/* <p className="AddressCardSecondaryTitle">
        Faks : <text className="AddressCardValues">{faks}</text>
      </p> */}
    </div>
  );
}

export default AddressCard;
