import React from "react";
import "../texts.css";

function DanışmaKuruluText(props) {
  return (
    <div id="DanışmaKurulu">
      <text className="Title" id="DanismaKuruluTitle">
        Danışma Kurulu
      </text>

      <p className="Paragraph">
        <br />
        Misalli Büyük Türkçe Sözlük ve İmlâ Kılavuzu çalışmalarına 1972 yılında
        ülkemizin en önde gelen ilim, fikir ve sanat adamlarından teşekkül eden
        bir heyetle başlanmıştır. Çoğu akademisyen olan bu heyet 1972 – 1976
        yılları arasında çalışmalarını sürdürmüş ve XIII. asırdan XX. asra kadar
        yüzlerce eser taranmıştır. Ancak bu çalışmaların başka bir işle berâber
        yürütülmesinin mümkün olmadığı anlaşılınca esas yük İlhan Ayverdi’nin
        omuzlarına binmiş ve bu kıymetli insanlar desteklerini danışman olarak
        devam ettirebilmişlerdir. Danışma Kurulunda yıllara göre şu isimler yer
        almıştır:
        <br />
        <text >
        • Sâmiha Ayverdi (1905-1993)
        </text>
        <br />
        <text >
        • Dr. Ekrem Hakkı Ayverdi (1899-1984)
        </text>
        <br />
        <text >
        • Nihad Sâmi Banarlı (1907-1974)
        </text>
        <br />
        <text >
        • Prof. Dr. Tahsin Banguoğlu (1904-1989)
        </text>
        <br />
        <text >
        • Ord. Prof. Dr. Ömer Lütfi Barkan (1902-1979)
        </text>
        <br />
        <text >
        • Prof. Dr. Kaya Bilgegil (1921-1987)
        </text>
        <br />
        <text >
        • Fâruk Nâfiz Çamlıbel (1898-1973)
        </text>
        <br />
        <text >
        • Prof .Dr. Abdülkadir İnan (1889-1976)
        </text>
        <br />
        <text >
        • Orhan Seyfi Orhon (1890-1972)
        </text>
        <br />
        <text >
        • Fevziye Abdullah Tansel (1912-1988)
        </text>
        <br />
        <text >
        • Prof. Dr. Faruk Kadri Timurtaş (1925-1982)
        </text>
      </p>
    </div>
  );
}

export default DanışmaKuruluText;
