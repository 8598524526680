import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TopHeader from "../components/TopHeader/TopHeader";
import Header from "../components/Header/Header";
import PageBody from "../components/PageBody/PageBody";
import Footer from "../components/Footer/Footer";
import SecondaryFooter from "../components/SecondaryFooter/SecondaryFooter";
import FixedHeader from "../components/FixedHeader/FixedHeader";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../axios/Axios";
import HomePageBody from "../components/HomePageBody/HomePageBody";
import axios from "axios";
import KelimeBulunamadi from "./KelimeBulunamadi";
import LoadingPage from "./LoadingPage";




function HomePage(props) {
  const navigate=useNavigate();
  const { word, page } = useParams();
  const [data, setData] = useState({ content: [], totalPages: 0, number: 0 });

  const suggestWord = async () => {
  
    let response;
    try {
      response = await axiosInstance.get(`suggest-word`);
      if (response.data.kelime != undefined) {
        navigate(`/s/${response.data.kelimeSiralama}`);
        // window.location.reload();
      } else {
        suggestWord();
      }
    } catch (error) {
      suggestWord();
    }
  
    // navigate(`/s/${value}`);
    // window.location.reload();
  };

  const getData = async () => {
    let response;
    if (page) {
      response = await axiosInstance.get(`s/${word}/${page}`, {
        
        crossdomain: true,
      });
      const res2 = await fetch(`https://eski.lugatim.com/rest/s/${word}/${page}`);
      const data = res2.json();

      setData(response.data);
    } else if(word==undefined){
      suggestWord();
    }
    else {
      response = await axiosInstance.get(`s/${word}`, { crossdomain: true });
      

      await fetch(`https://eski.lugatim.com/rest/s/${word}`)
        .then((response) => response.json())
        

      

      setData(response.data);
    }
    
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  },[word,page]);


  if ((!(data.content.length>0))&&data.url) {
    return(
      <Container fluid style={{ padding: 0 }}>
        <KelimeBulunamadi/>
      </Container>
    );
  }
  
  if (!(data.content.length > 0) && !data.url) {
    return (
      <Container fluid style={{ padding: 0 }}>
        <LoadingPage/>
      </Container>
    );
  }

  return (
    <Container fluid style={{ padding: 0 }}>
      {/* <TopHeader /> */}
      {/* <FixedHeader word={word}/> */}
      {/* <Header word={word} /> */}
      <HomePageBody
        data={data.content}
        page={data.number}
        totalPage={data.totalPages}
        word={word}
        searchInText={false}
      />
      {/* <Footer />
      <SecondaryFooter /> */}
    </Container>
  );
}

export default HomePage;
