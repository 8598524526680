import React, { useReducer, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sticky from "react-stickynode";
import { Colors } from "../../assets/Colors";
import "./pageBody.css";
import PageTabs from "../PageTabs/PageTabs";
import DonationCard from "../DonationCard/DonationCard";
import TakdimText from "../Texts/Takdim/TakdimText";
import HayatText from "../Texts/HayatHikayesi/HayatHikayesi";
import ÖnsözText from "../Texts/Önsöz/Önsöz";
import Space from "../Space/Space";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import YardımcılarText from "../Texts/Yardımcılar/Yardımcılar";
import DanışmaKuruluText from "../Texts/DanışmaKurulu/DanışmaKurulu";
import ÖzelAlanDanışmanlarıText from "../Texts/ÖzelAlanDanışmanları/ÖzelAlanDanışmanları"
import ÖdüllerText from "../Texts/Ödüller/Ödüller"
import BaskılarText from "../Texts/Baskılar/Baskılar";
import KısaBilgilerText from "../Texts/KısaBilgiler/KısaBilgiler";
import BasındaText from "../Texts/Basında/Basında";
import KitapReklam from "../KitapReklam/KitapReklam";
import CourseSlider from "../CourseSlider/CourseSlider";
import SlideShow from "../SlideShow/SlideShow";
import Announcement from "../Announcement/Announcement";

function PageBody(props) {
  const HayatRef = useRef(null);
  const TakdimRef = useRef(null);
  const ÖnsözRef = useRef(null);
  const YardımcılarRef=useRef(null);
  const DanışmaKuruluRef=useRef(null);
  const ÖzelAlanDanışmanlarıRef=useRef(null);
  const ÖdüllerRef=useRef(null);
  const BaskılarRef=useRef(null);
  const KısaBilgilerRef=useRef(null);
  const BasındaRef=useRef(null);

  const refs = {
    Hayat: HayatRef,
    Takdim: TakdimRef,
    Önsöz: ÖnsözRef,
    Yardımcılar:YardımcılarRef,
    DanışmaKurulu:DanışmaKuruluRef,
    ÖzelAlanDanışmanları:ÖzelAlanDanışmanlarıRef,
    Ödüller:ÖdüllerRef,
    Baskılar:BaskılarRef,
    KısaBilgiler:KısaBilgilerRef,
    Basında:BasındaRef
  };

  const ScrollHandler = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container
      fluid
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        backgroundColor: Colors.White,
        alignContent: "stretch",
      }}
      className="PageBody"
      id="PageBody"
    >
      <Row>
        <Col xs={12} lg={9}>
          <div ref={HayatRef}>
            <HayatText />
          </div>

          <div ref={TakdimRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <TakdimText />
          </div>

          <div ref={ÖnsözRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <ÖnsözText />
          </div>


          <div ref={YardımcılarRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <YardımcılarText />
          </div>

          <div ref={DanışmaKuruluRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <DanışmaKuruluText />
          </div>

          <div ref={ÖzelAlanDanışmanlarıRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <ÖzelAlanDanışmanlarıText/>
          </div>

          <div ref={ÖdüllerRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <ÖdüllerText/>
          </div>

          <div ref={BaskılarRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <BaskılarText/>
          </div>

          <div ref={KısaBilgilerRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <KısaBilgilerText/>
          </div>

          <div ref={BasındaRef}>
            <Space top={"60px"} />
            <HorizontalLine />
            <Space top={"60px"} />
            <BasındaText/>
          </div>
        </Col>

        <Col
          xs={12}
          lg={3}
          
        >
          <Sticky top="#FixedHeader" bottomBoundary="#PageBody" innerZ={10} >
            <Space top={"1vh"}></Space>
            <Row id="RightCol" style={{display:'flex',justifyContent:'flex-end'}}>
              <Col xs={12} lg={11}>
                {/* <DonationCard /> */}
                {/* <KitapReklam/> */}
                {/* <SlideShow/> */}

                {/* Duyuru */}
                <Announcement/>
              </Col>
            </Row>
            <div style={{ height: 15 }}></div>
            <Row style={{display:'flex',justifyContent:'flex-end'}}>
              <Col xs={12} lg={11} >
                <PageTabs refs={refs} ScrollHandler={ScrollHandler} />
              </Col>
            </Row>
          </Sticky>
        </Col>
      </Row>
    </Container>
  );
}

export default PageBody;
