import React from "react";
import "../texts.css";

function YardımcılarText(props) {
  return (
    <div id="Yardımcılar">
      <text className="Title" id="YardimcilarTitle">
        Yardımcılar
      </text>

      <p className="Paragraph">
        <br />
        İlhan Ayverdi sözlük maddelerini yazmaya 1976 senesinde başlamış, üç
        cilt hâlinde basılıncaya kadar özel ihtisas gerektiren konularda şu ilim
        adamlarıyla birlikte çalışmıştır:
        <br />
        <text >• Prof. Dr. Ahmet Topaloğlu - Redaksiyon-Etimoloji</text> 
        <br />
        <text >• Hayri Bilecik - Etimoloji (Arapça-Farsça)</text>
        <br />
        <text >• Prof. Dr. Mustafa Tahralı - İmlâ (Uygulama) - Etimoloji (Arapça-Farsça)</text>
        <br />
        <text >• Dr. Fahrünnisa Bilecik - Tashih - Ekler Kısmı</text> 
        
      </p>
    </div>
  );
}

export default YardımcılarText;
