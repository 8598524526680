import React from "react";

function HorizontalLine(props) {
  return (
    <div
      style={{
        width: "100%",
        borderWidth: 1,
        height: 0,
        borderStyle: "dotted",
        borderColor: "#0B778D",
        borderTop:0
      }}
    ></div>
  );
}

export default HorizontalLine;
