import React from "react";
import { Colors } from "../../assets/Colors";
import "./Announcement.css"

function AnnouncementCard({name,img,url}) {
  return (
    <a
      className="CourseSliderCard"
      href={url}
      target="_blank"
    >
      <img src={img} className="CourseSliderImage"></img>
      {/* <span>{name}</span> */}
    </a>
  );
}

export default AnnouncementCard;
