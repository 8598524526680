import React, { useState } from "react";
import {
  Col,
  Container,
  Image,
  InputGroup,
  Row,
  Form,
  ButtonGroup,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { Colors } from "../../assets/Colors";
import {
  KeyboardSvg,
  SearchSvg,
  SearchinTextSvg,
  ShuffleSvg,
} from "../../assets/svg";
import Line from "../Line/Line";
import "./header.css";
import Search from "../Search/Search";
import { axiosInstance } from "../../axios/Axios";
import { useNavigate } from "react-router-dom";

function Header({ word }) {
  const navigate = useNavigate();
  const kubbeAltıLogo = require("../../assets/img/lugatLogo.png");

  const [searchInText, setSearchInText] = useState(false);

  const searchInTextHandler = () => {
    setSearchInText(!searchInText);
  };

  const suggestWord = async () => {
    let response;
    try {
      response = await axiosInstance.get(`suggest-word`);
      if (response.data.kelime != undefined) {
        navigate(`/s/${response.data.kelimeSiralama}`);
        window.location.reload();
      } else {
        suggestWord();
      }
    } catch (error) {
      suggestWord();
    }

    // navigate(`/s/${value}`);
    // window.location.reload();
  };

  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Metin İçi Arama <br/> {searchInText?"(Etkin)":"(Etkin Değil)"}</strong>
    </Tooltip>
  );

  const tooltipKeyboard = (
    <Tooltip id="tooltip">
      <strong>Klavye</strong>
    </Tooltip>
  );

  const tooltipShuffle = (
    <Tooltip id="tooltip" >
      <strong>Rastgele Kelime Getir</strong>
    </Tooltip>
  );

  return (
    <Container
      fluid
      className="Header"
      style={{
        backgroundColor: Colors.Blue,
        height: "25vh",
        backgroundImage: `url(
          "https://s3-alpha-sig.figma.com/img/6d6c/6b7a/72b080c525f06c4baed8a9c17a5cf289?Expires=1701648000&Signature=j9UIy8uPglnEnxR8vJjq4X2MAz5dEAmpYbak9eXWiIoYE5QpGfemD~XP-jU54EL0LZNizu1uUTuiD2RvNsYWM0DToSb00lK5DIMiSisV-B1tvU-LtN3HHyanBD4yOQs3~2ZQxOJW874ay7oa373aNtYchjYlLt7VkvNb-NaPXGGiiu4yv7X5nqi~MroiUbL648EZojmrwC7~TvjTUe~veKKhPS0GQPvf-FaJ~8E3O1XHhxgCV~zLesSNX5jqGAdJ~BD6z2nbMm1djFfP7hbT-njf1muUyMNryjb~T56JTw4qVFCI~6vsbUrpABoGHN7wUzKXpOcXx5JkrrwJ5PzG0Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
        )`,
        paddingRight: "5vw",
        paddingLeft: "10vw",
        zIndex: 10,
        position: "relative",
      }}
      id="Header"
    >
      <Row style={{ height: "25vh" }}>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 30,
          }}
        >
          <Image
            src={kubbeAltıLogo}
            style={{ width: "28vw", height: "7.5vh" }}
          />
          <Row>
            <Col xs={8}>
              {/* <InputGroup
                style={{ width: "26vw", height: "5.8vh", borderRadius: 15 }}
              >
                <Form.Control
                  type="email"
                  placeholder="Kelime"
                  style={{
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 15,
                  }}
                />
                <InputGroup.Text
                  style={{
                    backgroundColor: Colors.LightBlue,
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15,
                    cursor: "pointer",
                  }}
                  
                >
                  <SearchSvg width={"1.6vw"} height={"3vh"} color={"#FFF"} />
                </InputGroup.Text>
              </InputGroup> */}
              <Search word={word} searchInText={searchInText} />
            </Col>

            <Col xs={4}>
              <div
                className="me-2"
                aria-label="First group"
                style={{
                  width: "11.5vw",
                  height: "5.8vh",
                  backgroundColor: Colors.LightBlue,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  borderRadius: 15,
                }}
              >
                <OverlayTrigger placement="bottom" overlay={tooltip}>
                  <Button
                    style={{ backgroundColor: Colors.LightBlue, border: 0 }}
                    onClick={searchInTextHandler}
                    // title={`Metin İçi Arama  (${
                    //   searchInText ? "Aktif" : "Pasif"
                    // })`}
                  >
                    <SearchinTextSvg
                      width={"1.7vw"}
                      height={"3vh"}
                      color={"#FFF"}
                      opacity={searchInText ? 1 : 0.5}
                    />
                  </Button>
                </OverlayTrigger>
                <Line />
                <OverlayTrigger placement="bottom" overlay={tooltipKeyboard}>
                <Button
                  style={{ backgroundColor: Colors.LightBlue, border: 0 }}
                  title="Klavye"
                >
                  <KeyboardSvg width={"1.7vw"} height={"3vh"} color={"#FFF"} />
                </Button>
                </OverlayTrigger>
                <Line />
                <OverlayTrigger placement="bottom" overlay={tooltipShuffle}>
                <Button
                  style={{ backgroundColor: Colors.LightBlue, border: 0 }}
                  onClick={() => {
                    suggestWord();
                  }}
                  title="Rastgele Kelime Getir"
                >
                  <ShuffleSvg width={"1.3vw"} height={"3vh"} color={"#FFF"} />
                </Button>
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{
              width: "23.5vw",
              height: "20vh",
              backgroundColor: "red",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            Sponsor reklamı
          </div>
        </Col>
        <div className="SecondaryHeaderAyverdi" style={{ width: "10vw" }}></div>
      </Row>
    </Container>
  );
}

export default Header;
