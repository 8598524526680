import React from "react";
import "../texts.css";
import './hayatHikayesi.css'
import { Col, Row } from "react-bootstrap";

function HayatText(props) {
  return (
    <div id="Hayat">
      <text className="Title" id="HayatTitle">
        Kısa Hayat Hikayesi
      </text>
      <Row>
        <Col xs={12} lg={3} className="ImageContainer">
          <p className="Paragraph">
            <br />
            <img
              src={require("../../../assets/img/iayverdi1b.jpg")}
              style={{ width: "100%", marginTop: 10 }}
            />
            <img
              src={require("../../../assets/img/iayverdi2b.jpg")}
              style={{ width: "100%", marginTop: 50 }}
            />
          </p>
        </Col>

        <Col>
          <p className="Paragraph">
            <br />
            24 Ekim 1926'da Akhisar’da doğdu. Baba tarafı Dağıstan, anne tarafı
            Rumeli asıllıdır. Ailesi, 93 Harbi sırasında topraklarını terk edip
            önce Bursa'ya sonra Ödemiş'e yerleşmişti. Babası Murat Tolun Bey,
            Kurtuluş Savaşı yıllarında Galip Hoca diye bilinen Celal Bayar'ın
            silah arkadaşıdır; Kurtuluş Savaşı'nda Akhisar Cephesi Kuvâyı
            Milliye Kumandanı olarak görev yapmıştır ve İstiklal madalyası
            sahibidir.
            <br />
            <br />
            İlk ve orta tahsilini Akhisar’da, lise tahsilini ise Akhisar'da lise
            olmadığından İzmir Karataş Lisesi'nde, 1943 senesinde tamamladı.
            Hastalık sebebiyle iki sene ara verdiği tahsil hayatına İstanbul
            Üniversitesi Edebiyat Fakültesi Türk Dili ve Edebiyatı Bölümü'nde
            devam etti. Türk tarihinin ilk edebiyat profesörü olan Ali Nihad
            Tarlan, edebiyat tarihçisi İsmail Hikmet Ertaylan ve Prof. Mehmet
            Kaplan'ın öğrencisi oldu. Fakültede öğrenci iken bir yandan da
            İstanbul Milli Eğitim Müdürlüğü'nde memur olarak çalıştı; burada
            Mehmet Örtenoğlu ile tanışması bütün hayatını etkiledi. Mehmet Dede
            vasıtasıyla yazar Sâmiha Ayverdi ve Sâmiha Hanım'ın hocası Kenan
            Rıfaî (Kenan Büyükaksoy) ile tanıştı. Üniversite öğrenimini 1949
            yılında tamamladı.
            <br />
            <br />
            Edebiyat öğretmeni olan İlhan Hanım, sırası ile Anarat Higutyan
            Ermeni Orta Okulu, Galatasaray Lisesi, Zoğrafyon Rum Lisesi
            (1951-1955), Saint Joseph Lisesi ve Saint Michel Fransız Lisesi’nde
            görev yaptı. 1960 yılında ise Çapa Eğitim Enstitüsü'nde bir süre
            öğretmenliğe devam etti ve aynı yıl öğretmenlikten ayrıldı. 1959’da
            Sâmiha Ayverdi'nin erkek kardeşi Yük. Mimar-Mühendis Ekrem Hakkı
            Ayverdi ile evlendi. Ekrem Hakkı Bey’in, Anadolu ve Rumeli'ni karış
            karış gezerek 20 senelik bir çalışma neticesi tamamladığı dört
            ciltlik Osmanlı Mimari Eserleri’nin hazırlanmasına katkıda bulundu.
            <br />
            <br />
            1966-1982 yılları arasında Türk Kadınları Kültür Derneği'nin
            başkanlığını yaptı. 1970’de Kubbealtı Cemiyeti'nin kuruluşunu,
            Sâmiha ve Ekrem Hakkı Ayverdi ile birlikte deruhte etti, cemiyetin
            isim annesi oldu. Bu cemiyet, Kubbealtı Akademisi Kültür ve Sanat
            Vakfı adıyla vakfa dönüşünce, vakfın başkanı oldu. Kubbealtı
            Mecmuasının yayınlanmasında, vakfın yayın, seminer, konferans,
            musikî çalışmaları ve çeşitli sosyal faaliyetlerinde eşi Ekrem Hakkı
            Ayverdi ve Sâmiha Ayverdi ile birlikte çaba sarf etti.
            <br />
            <img
              className="ImageContainer2"
              src={require("../../../assets/img/iayverdi1b.jpg")}
            />
            <br />
            İlk çalışmaları 1971 senesinde başlayan ve 1978 yılında Kubbealtı
            Vakfı'nın, neşrine karar verdiği Misâlli Büyük Türkçe Sözlüğün
            hazırlanması ve yazılması işini üstlendi. Bu eser üzerinde 34 yıl
            aralıksız çalıştı ve 2005 yılında tamamladı. 13. yüzyıldan günümüze
            kadar Türk dilinin envanterini çıkaran ve binlerce misalle donatılan
            bu eser, Kasım 2005’te yayınlandı. İlerleyen tarihlerde İlhan
            Ayverdi, bu eseri ile çeşitli ödüllere lâyık görüldü.
            <br />
            <img
              className="ImageContainer2"
              src={require("../../../assets/img/iayverdi2b.jpg")}
            />
            <br />6 Kasım 2009'da İstanbul'da vefat eden İlhan Ayverdi,
            Merkezefendi Kabristanında medfundur.
          </p>
        </Col>
      </Row>

      {/* <text className="Subtitle">
        İlhan Ayverdi
        <br />
        <br />
        Kasım, 2005
      </text> */}
    </div>
  );
}

export default HayatText;
