import React from "react";
import "../texts.css";

function ÖzelAlanDanışmanlarıText(props) {
  return (
    <div id="ÖzelAlanDanışmanları">
      <text className="Title" id="OzelAlanDanismanlariTitle">
        Özel Alan Danışmanları
      </text>

      <p className="Paragraph">
        <br />
        <text >• Prof. Dr. Târık Akçal (Tıp)</text>
        <br />
        <text >
          • Dr. İnci Ayan Birol (Süsleme Sanatları)
        </text>
        <br />
        <text >
          • Prof. Dr. Çiçek Derman (Süsleme Sanatları)
        </text>
        <br />
        <text >
          • Prof. (h.c.) Uğur Derman (Hat, Süsleme Sanatları)
        </text>
        <br />
        <text >• Prof. Dr. Mustafa Fayda (İlâhiyat)</text>
        <br />
        <text >• Prof. Dr. Kenan Gürsoy (Felsefe)</text>
        <br />
        <text >• Prof. Dr. Fehmi Mercanoğlu (Tıp)</text>
        <br />
        <text >
          • Prof. Dr. Fahrettin Olguner (İslâm Felsefesi)
        </text>
        <br />
        <text >• Yusuf Ömürlü (Mûsikî)</text>
        <br />
        <text >• Prof. Dr. Fevzi Samuk (Tıp)</text>
        <br />
        <text >• Prof. Dr. Muhittin Serin (Hat)</text>
        <br />
        <text >
          • Prof. Dr. Vildan Serin (İktisat, Sosyoloji)
        </text>
        <br />
        <text >• Prof. Dr. Ali Yardım (İlâhiyat)</text>
        <br />
        <text >• Prof. Dr. Bayram Yüksel (Teknik)</text>
        <br />
        <text >• Dr. İ. Aydın Yüksel (Mîmârî)</text>
        <br />
        <text>• Prof. Dr. Semahat Yüksel (Filoloji)</text>
      </p>
    </div>
  );
}

export default ÖzelAlanDanışmanlarıText;
