import React from 'react';

function Space({top}) {
    return (
        <div style={{height:top}}>
            
        </div>
    );
}

export default Space;