import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TopHeader from "../components/TopHeader/TopHeader";
import Header from "../components/Header/Header";
import PageBody from "../components/PageBody/PageBody";
import Footer from "../components/Footer/Footer";
import SecondaryFooter from "../components/SecondaryFooter/SecondaryFooter";
import FixedHeader from "../components/FixedHeader/FixedHeader";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../axios/Axios";
import HomePageBody from "../components/HomePageBody/HomePageBody";
import LoadingPage from "./LoadingPage";
import KelimeBulunamadi from "./KelimeBulunamadi";
import HomePageMobile from "./HomePageMobile";
import MobileHomePageBody from "../components/MobileHomePageBody/MobileHomePageBody";

function HomePageForTextSearch(props) {
  const { word, page } = useParams();
  const [data, setData] = useState({ content: [], totalPages: 0, number: 0 });

  const getData = async () => {
    let response;
    if (page) {
      response = await axiosInstance.get(`s/${word}/e/${page}`);
    } else {
      response = await axiosInstance.get(`s/${word}/e`);
    }
    setData(response.data);
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, [word, page]);

  
  if ((!(data.content.length>0))&&data.url) {
    return(
      <Container fluid style={{ padding: 0 }}>
        <KelimeBulunamadi/>
      </Container>
    );
  }

  if (!(data.content.length > 0) && !data.url) {
    return (
      <Container fluid style={{ padding: 0 }}>
        <LoadingPage/>
      </Container>
    );
  }

  return (
    <Container fluid style={{ padding: 0 }}>
      {/* <TopHeader/> */}
      {/* <FixedHeader word={word}/> */}
      {/* <Header word={word}/> */}
      <HomePageBody
        data={data.content}
        page={data.number}
        totalPage={data.totalPages}
        word={word}
        searchInText={true}
      />
      {/* <Footer/> */}
      {/* <SecondaryFooter/> */}
    </Container>
  );
}

export default HomePageForTextSearch;
