import React, { useEffect, useReducer, useState } from "react";
import {
  Col,
  Container,
  Image,
  InputGroup,
  Row,
  Form,
  ButtonGroup,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Colors } from "../../assets/Colors";

import { SearchSvg } from "../../assets/svg";
import { Typeahead } from "react-bootstrap-typeahead";
// import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';
import "./search2.css";

import { axiosInstance } from "../../axios/Axios";

import { useNavigate, useNavigation } from "react-router-dom";
import KeyboardDeneme from "../KeyboardDeneme/KeyboardDeneme";

let inputNumber = 0;

function Search({ word, searchInText, setMainWord, keyboard, setKeyboard }) {
  const navigate = useNavigate();

  const tooltipSearch = (
    <Tooltip id="tooltip">
      <strong>Ara</strong>
    </Tooltip>
  );

  const [selected, setSelected] = useState();
  const [value, setValue] = useState(word);
  const [suggestions, setSuggestion] = useState([]);
  const [word2, setWord2] = useState("");
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const getValue = () => {
    return value;
  };

  const getSuggestion = async (word) => {

    if (word.length > 1) {
      const response = await axiosInstance.get(`word-search/${word}`);
      let _words = [];
      response.data.forEach((element) => {
        _words.push(element.display);
      });
      const _value = getValue();
      if (_value != word) {
        setSuggestion(_words);
      }
    } else {
      setSuggestion([]);
    }
  };

  const suggestionsHandler = async (input, n) => {

    if (n == input.length) {
      getSuggestion(input);
    }
  };

  const searchFunction = async () => {
    if (value) {
      if (searchInText) {
        navigate(`/e/${value.replace(/\s/g, "")}`);
      } else {
        navigate(`/s/${value.replace(/\s/g, "")}`);

      }
    }

  };

  const selectSearch = async (selected) => {
    if (selected[0][0]=="–") {
      selected[0]=selected[0].substring(1);
    }
    navigate(`/s/${selected[0].replace(/\s/g, "")}`);
  };

  useEffect(() => {
    getSuggestion(value);
  }, []);

  useEffect(() => {
    if (value) {
      setSelected([value]);
      setValue(value);
    } else {
      setSelected([""]);
      setValue("");
    }
    forceUpdate();
  }, [value]);

  return (
    <Form.Group
      onSubmit={searchFunction}
      onKeyDown={(e) => {
        if (e.key == "Enter") {
          searchFunction();
        }
      }}
    >
      <InputGroup style={{ width: "26vw", height: "5.8vh", borderRadius: 15 }}>
        <Typeahead
          // clearButton
          id="Search"
          className="InputProp"
          inputProps={{
            style: {
              borderRadius: 0,
              borderTopLeftRadius: 15,
              borderBottomLeftRadius: 15,
              borderColor: Colors.LightBlue,
              outline: "none",
              outlineOffset: "none",
              border: "none",

              // boxShadow:"none"
              boxShadow: "rgba(0, 0, 0, 0.03) 0px 3px 0px",
            },
          }}
          emptyLabel="Kelime Bulunamadı"
          onInputChange={async (input) => {
            setValue(input);
            // setWord2(input);
            setSelected("");
            inputNumber = input.length;
            setTimeout(() => suggestionsHandler(input, inputNumber), 300);
          }}
          onChange={(selected) => {
            setSelected(selected);
            if (selected.length > 0) {
              selectSearch(selected);
            }
          }}
          options={suggestions}
          selected={selected}
          defaultInputValue={word2}
          placeholder="Kelime ..."
        />
          
        <OverlayTrigger placement="bottom" overlay={tooltipSearch}>
          <InputGroup.Text
            style={{
              backgroundColor: Colors.LightBlue,
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
              flexShrink: 0,
              border: 0,
              // width:'15%',
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
            onClick={searchFunction}
          >
            <SearchSvg width={"1.7vw"} height={"3vh"} color={"#FFF"} />
          </InputGroup.Text>
        </OverlayTrigger>
      </InputGroup>
      {keyboard && (
        <KeyboardDeneme
          word={value}
          setWord={setValue}
          setKeyboard={setKeyboard}
          getWord={getValue}
        />
      )}
    </Form.Group>
  );
}

export default Search;
