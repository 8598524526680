import React from "react";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../assets/Colors";
// import "./pagination.css";

function MobilePagination({ totalPages, pageNumber, word,searchInText }) {
  const navigate = useNavigate();
  const items = [];
  let maxPage = 0;
  let minPage = 1;

  if (totalPages > 4) {
    maxPage = pageNumber + 2;
    if (maxPage > totalPages) {
      maxPage = totalPages;
    }
  } else {
    maxPage = totalPages;
  }

  if (pageNumber > 2) {
    minPage = pageNumber - 2;
  }

  for (let i = minPage; i <= maxPage; i++) {
    items.push(i);
  }
  
  return (
    <Container
      fluid
      style={{
        // backgroundColor: "red",
        padding: 0,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Pagination>
        <Pagination.First
          linkClassName="PaginationItemStyle"
          disabled={pageNumber == 1}
          onClick={() => {
            if (searchInText) {
              navigate(`/m/e/${word}/${1}`);
            }else{
              navigate(`/m/s/${word}/${1}`);
            }
            // window.location.reload();
          }}
        />
        <Pagination.Prev
          linkClassName="PaginationItemStyle"
          disabled={pageNumber == 1}
          onClick={() => {
            if (searchInText) {
              navigate(`/m/e/${word}/${pageNumber - 1}`);
            }else{
              navigate(`/m/s/${word}/${pageNumber - 1}`);
            }
            // window.location.reload();
          }}
        />
        {items.map((item) => {
          return (
            <Pagination.Item
              // active={item == pageNumber}
              onClick={() => {
                if (searchInText) {
                  navigate(`/m/e/${word}/${item}`);  
                }else{
                  navigate(`/m/s/${word}/${item}`);
                }
                // window.location.reload();
              }}
              linkClassName={`PaginationItemStyle${(item == pageNumber)?'Active':''}`}
            >
              {item}
            </Pagination.Item>
          );
        })}
        <Pagination.Next
          linkClassName="PaginationItemStyle"
          disabled={pageNumber == totalPages}
          onClick={() => {
            if (searchInText) {
              navigate(`/m/e/${word}/${pageNumber + 1}`);
            }else{
              navigate(`/m/s/${word}/${pageNumber + 1}`);
            }
            // window.location.reload();
          }}
        />
        <Pagination.Last
          linkClassName="PaginationItemStyle"
          disabled={pageNumber == totalPages}
          onClick={() => {
            if (searchInText) {
              navigate(`/m/e/${word}/${totalPages}`);  
            }else{
              navigate(`/m/s/${word}/${totalPages}`);
            }
            // window.location.reload();
          }}
        />
      </Pagination>
    </Container>
  );
}

export default MobilePagination;
