import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Colors } from "../../assets/Colors";
import "./footer.css";
import SecondDonationCard from "../SecondDonationCard/SecondDonationCard";
import MobileApps from "../MobileApps/MobileApps";
import Sticky from "react-stickynode";
import { YılSvg } from "../../assets/svg";
import FooterMenu from "../FooterMenu/FooterMenu";

function Footer(props) {
  const logo = require("../../assets/img/kubbealtı.png");
  return (
    <Container
      fluid
      className="Footer"
      style={{
        backgroundColor: Colors.Blue,
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
      id="Footer"
    >
      <Row style={{ width: "100%" }}>
        <Col
          xs={12}
          lg={2}
          style={{ padding: 0 }}
          className="FooterImageContainer"
        >
          {/* <Image src={logo} className="Logo" /> */}
          <div style={{width:"100%",display:"flex",justifyContent:'center'}}>
            <YılSvg  height={"12.2vh"} width={"90%"} />
          </div>
          {/* muaz */}
        </Col>

        <Col style={{ padding: 0,justifyContent:'center',display:'flex',alignItems:'center' }} className="FooterMenuColumn" lg={2} xs={12}>
          <FooterMenu/>
        </Col>

        <Col
          xs={12}
          lg={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
          }}
          className="FooterDonationContainer"
        >
          <SecondDonationCard />
        </Col>

        <Col
          xs={12}
          lg={4}
          style={{
            padding: 0,
          }}
          className="FooterMobilAppContainer"
        >
          <MobileApps />
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
