import React from "react";

function Osmanlica(props) {
  return (
    <a
      href="https://kubbealti.org.tr/kurs/uzaktan-egitim-osmanli-turkcesi-baslangic-seviyesi"
      target="_blank"
    >
      <img
        src={require("../../assets/img/lugatim_osmanlica.png")}
        style={{ width: "100%", cursor: "pointer" }}
      />
    </a>
  );
}

export default Osmanlica;
