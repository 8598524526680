import React from "react";
import "./mobileApps.css";
import { Col, Container, Row } from "react-bootstrap";
import MobileAppsCard from "./MobileAppsCard";

function MobileApps(props) {
  return (
    <div>
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding:0
        }}
      >
        <Col className="MobileApps">
          <text className="MobileAppsText">Mobil Uygulamalar</text>
          <Row>
            <Col>
              <MobileAppsCard type={"App Store"}/>
            </Col>
            <Col>
              <MobileAppsCard type={"Google Play"}/>
            </Col>
          </Row>
        </Col>
      </Container>
    </div>
  );
}

export default MobileApps;
