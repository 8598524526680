import React from "react";
import "../texts.css";

function KısaBilgilerText(props) {
  return (
    <div id="KısaBilgiler">
      <text className="Title" id="KisaBilgilerTitle">
        Kısa Bilgiler
      </text>

      <p className="Paragraph">
        <br />
        <text >• Başvurulan kaynak eser sayısı: 65</text>
        <br />
        <text >
          • Taranan yazar, şâir, ilim adamı sayısı: 200
        </text>
        <br />
        <text >• Taranan eser sayısı: 438</text>
        <br />
        <text >
          • Örnekler alınan yazar, şâir, ilim adamı ve sanatkâr sayısı: 840
        </text>
        <br />
        <text >• Toplam 93.000 söz varlığı</text>
        <br />
        <text >• 60.000 açıklamalı madde</text>
        <br />
        <text >
          • 33.000 maddelerden türeyen açıklamalı deyim
        </text>
        <br />
        <text >
          • 100.000’e yakın yazarlı yazarsız örnek
        </text>
      </p>
    </div>
  );
}

export default KısaBilgilerText;
