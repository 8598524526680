import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./secondaryFooter.css";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "../../assets/svg";
import SocialMediaButtons from "../SocialMediaButtons/SocialMediaButtons";

function SecondaryFooter(props) {
  return (
      <Container fluid className="SecondaryFooter" style={{paddingLeft:'5vw',paddingRight:'5vw'}} id="Footer">
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width:'100%',
            padding:0
          }}
        >
          <Col style={{padding:0}}>
            <text className="SecondaryFooterText">2024 © Kubbealtı Lugatı - Her hakkı mahfuzdur.</text>
          </Col>
          <Col style={{justifyContent:'flex-end',display:'flex',padding:0}}>
            <SocialMediaButtons/>
          </Col>
        </Row>
      </Container>
  );
}

export default SecondaryFooter;
