import React from "react";
import { Colors } from "../../assets/Colors";
import { Col, Container, Row } from "react-bootstrap";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import Space from "../Space/Space";
import Sticky from "react-stickynode";
import "./Privacy.css";
import Osmanlica from "../Osmanlica/Osmanlica";
import KitapReklam from "../KitapReklam/KitapReklam";
import SlideShow from "../SlideShow/SlideShow";
import CourseSlider from "../CourseSlider/CourseSlider";
import Announcement from "../Announcement/Announcement";

function PrivacyPageBody(props) {
  return (
    <Container
      fluid
      className="PageBody"
      id="PageBody"
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        backgroundColor: Colors.White,
        alignContent: "stretch",
        // gap: 20,
        justifyContent: "flex-start",
        //   minHeight:"75vh"
      }}
      // className="ContactPageBody"
    >
      <Row>
        <Col xs={12} lg={9} style={{ gap: 20 }}>
          <p className="PrivacyTitle">Gizlilik Politikası</p>
          <HorizontalLine />
          <Space top={20} />

          <p className="PrivacyNormalText">
            Kubbealtı Lugati, www.lugatim.com web sitesi ve Lugatim adlı mobil
            uygulamalarıyla elektronik sözlük hizmeti vermektedir. Kubbealtı
            Lugati olarak, kullanıcılarımızın gizliliğini korumak ve kişisel
            bilgilerinin güvenliğini sağlamak en üst düzeyde önceliğimizdir. Bu
            gizlilik politikası, Kubbealtı Lugati kullanıcılarının hangi
            bilgilerinin toplandığını, bu bilgilerin nasıl kullanıldığını ve
            korunduğunu açıklar.
          </p>
          <Space top={40} />
          {/* ///// */}
          <p className="PrivacyTitle">Toplanan Bilgiler</p>
          <HorizontalLine />
          <Space top={20} />

          <p className="PrivacyNormalText">
            Kubbealtı Lugati, kullanıcılarının deneyimlerini iyileştirmek ve
            hizmetlerini optimize etmek için Google Analytics gibi üçüncü taraf
            analiz hizmetlerini kullanır. Bu hizmetler, kullanıcı etkileşimleri
            hakkında çeşitli bilgiler toplayabilir: hangi sözcüklere arama
            yapıldığı, hangi sayfalara erişildiği, ne kadar zaman harcandığı
            gibi. Ayrıca, cihazın IP adresi, tarayıcı türü, dil tercihleri ve
            işletim sistemi gibi teknik bilgiler de toplanabilir.
          </p>
          <Space top={40} />

          {/* ///// */}
          <p className="PrivacyTitle">Çerezler ve Benzeri Teknolojiler</p>
          <HorizontalLine />
          <Space top={20} />

          <p className="PrivacyNormalText">
            Kubbealtı Lugati'nin www.lugatim.com web sitesi ve mobil
            uygulamaları, kullanıcıların tercihlerini hatırlamak ve daha iyi
            hale getirmek için çerezler ve benzeri teknolojiler kullanır. Bu
            teknolojiler, kullanıcıların tekrar tekrar girmelerini gerektiren
            bilgileri hatırlar ve kullanıcı oturumlarını izlemek için
            kullanılır.
          </p>

          <Space top={40} />

          {/* ///// */}
          <p className="PrivacyTitle">Kullanım Amaçları</p>
          <HorizontalLine />
          <Space top={20} />

          <p className="PrivacyNormalText">
            Topladığımız bilgileri aşağıdaki amaçlarla kullanıyoruz:
            <li>Kullanıcı deneyimini kişiselleştirmek ve iyileştirmek,</li>
            <li>Hizmetlerimizi analiz etmek ve geliştirmek,</li>
            <li>
              Kubbealtı Lugati'nin pazarlama ve reklam faaliyetlerini yönetmek,
            </li>
            <li>Müşteri taleplerine cevap vermek ve destek sağlamak,</li>
            <li>Yasal gerekliliklere uymak.</li>
          </p>
          <Space top={40} />
          {/* ///// */}
          <p className="PrivacyTitle">Bilgi Paylaşımı</p>
          <HorizontalLine />
          <Space top={20} />

          <p className="PrivacyNormalText">
            Topladığımız bilgileri yalnızca yasal yükümlülükler gerektirdiğinde
            veya hizmetlerimizi sunmak için iş birliği yaptığımız üçüncü
            taraflarla paylaşırız. Kullanıcıların kişisel bilgilerini ticari
            amaçlarla satmayız, kiralamayız veya değiştirmeyiz.
          </p>

          <Space top={40} />

          {/* ///// */}
          <p className="PrivacyTitle">Değişiklikler ve Güncellemeler</p>
          <HorizontalLine />
          <Space top={20} />

          <p className="PrivacyNormalText">
            Bu gizlilik politikası zaman zaman güncellenebilir. Değişiklikler
            yürürlüğe girdiğinde, politikadaki güncellemeleri burada
            yayınlayacağız. Politikadaki önemli değişiklikler olduğunda, size
            bildirimde bulunabiliriz veya politikadaki değişiklikleri belirli
            bir tarih öncesi yayınlayabiliriz.
          </p>

          <Space top={40} />

          {/* ///// */}
          <p className="PrivacyTitle">İletişim</p>
          <HorizontalLine />
          <Space top={20} />

          <p className="PrivacyNormalText">
            Bu gizlilik politikası ile ilgili herhangi bir sorunuz veya
            endişeniz varsa, lütfen bize web sitemizdeki iletişim formunu
            kullanarak ulaşın.
          </p>

          <Space top={40} />
        </Col>
        <Col xs={12} lg={3}>
          <Sticky
            top="#FixedHeader"
            bottomBoundary="#PageBody"
            shouldFreeze={() => {
              if (window.screen.width <= 991) {
                return true;
              } else {
                return false;
              }
            }}
          >
            {/* <Space top={"1vh"}></Space> */}
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Col xs={12} lg={1}></Col> */}
              <Col xs={12} lg={11}>
                {/* <DonationCard /> */}
                {/* <KitapReklam/> */}
                
                {/* <SlideShow/> */}
                {/* Duyuru */}
                <Announcement/>
              </Col>
            </Row>
            <div style={{ height: 15 }}></div>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col xs={12} lg={11}>
                {/* <Osmanlica/> */}
                <CourseSlider/>
              </Col>
            </Row>
          </Sticky>
        </Col>
      </Row>
    </Container>
  );
}

export default PrivacyPageBody;
