import React from "react";
import "../texts.css";

function ÖnsözText(props) {
  return (
    <div id="Önsöz">
      <text className="Title" id="OnsozTitle">
        Önsöz
      </text>

      <p className="Paragraph">
        <br />
        Bu sözlük, sâdece yaşayan Türkçemizi değil târihî seyri içinde Türk
        dilinin kazanmış olduğu zenginlikleri de gözler önüne sermek, Türk
        çocuklarına geçmişleriyle bağ kurmalarında ve milletlerin târihlerinde
        daha dün demek olan 100 – 150 senelik metinleri okuyup
        anlayabilmelerinde yardımcı olmak amacıyla hazırlanmıştır.
        <br />
        <br />
        Dil taşıyıcıdır; bir milletin kültürünü, sanatını, îmânını, düşünüş
        sistemini, yaşayış özelliklerini, sâhip olduğu değerleri asırlar boyunca
        dünden bu güne taşıyan kutsal bir nehir gibidir. Bu sözlük Türk dilinin
        bu nehirden alınan bir dökümü, bir nevi envanteridir. Târihî dil
        sözlüğü, konuşulan dilin sözlüğü vb. tasniflerin dışındadır.
        <br />
        <br />
        Sözlüğümüzde devirlerini tamamlayıp unutulmakta olan ve büyük bir
        gayretle dilimizden atılmak istenen kelimelere, yaşayan Türkçe
        kelimelere ve yeni türetilenlere yer verilmiş olması bundan dolayıdır.
        Bizi bu yola sevkeden, Türk diline yapılan kasıtlı müdâhaleler sonucunda
        dilimizin gittikçe fakirleşmekte ve ifâde yeteneğini kaybetmekte
        oluşudur. Daha önce kullanılmış olan âşikâr, bedîhî, dekolte, münhal,
        müstehcen… gibi 12 kelimenin bir tek açık kelimesiyle karşılanması dilde
        nasıl bir kavram kargaşasına yol açar, nüanslar nasıl kaybolur ve bu
        müdâhale dili nasıl fakirleştirir, düşünülmeye değer! Bu pek çok
        misalden bir tânesidir.
        <br />
        <br />
        Asırlar boyu kullanılan türkülerimizde, şarkılarımızda, masallarımızda,
        şiirlerimizde yer alan ve zamânımıza kadar dipdiri gelen kelimelerimiz
        Türk çocuklarına unutturulmuş ve yerlerine dilimizin kuralları ile
        bağdaşmayan uydurmaları veya yabancı kökenli olanları konulmuştur. Bu
        durumda günümüzde yaşayan dilin sözlüğü gibi bir daraltmaya gidilirse
        bunun sınırını tâyin mümkün değildir. Bugünkü dille bir Türk çocuğu bir
        Mehmet Âkif’i, bir Yahyâ Kemal’i ve hatta Atatürk’ü bile anlamaktan
        âcizdir. Bizim yaptığımız ne varsa, ne var idiyse malzemeyi ortaya
        koymaktır. İleride bu malzeme üzerinde yapılacak ayıklamalarla yaşayan
        dilimizin sözlüğünü ortaya koymak her zaman mümkündür.
        <br />
        <br />
        Türkiye Türkçesi’nin söz varlığını kapsayan sözlüğümüzde:
        <br />
        <text >a)</text> Yaşayan dilimizin kelimelerine
        <br />
        <text >b)</text> Deyimlere
        <br />
        <text >c)</text> Terimlere
        <br />
        <text >ç)</text> Yer yer mazmunlara, edebî mânâlara ve ansiklopedik açıklamalara da yer verilmiştir.
        <br />
        <text >d)</text> XIII. yüzyıldan îtibâren var olup bâzıları halk ağzında yaşamakta devam eden ve Arap harşeriyle yazılan eserlerde, belgelerde yer alan kelimeler Eski Türkiye Türkçesi ve halk ağzı. notu konularak gösterilmiştir.
        Bu kelimelerden bir kısmı bugün Kemal Tâhir, Mustafa Necâti Sepetçioğlu gibi yazarlarımızın eserlerinde de yer almaktadır.
        <br />
        <text >f)</text> Türkçesi olduğu halde yaygınlaşan yabancı kelimeler, Türkçesi olmayan yabancı kelimeler de sözlüğümüze alınmıştır.
        <br />
        <br />
        Sözlük genellikle fikir yürütmez, tespit eder, elimizdeki malzemeye göre şekillenir.
        Tablo bu… Bu tablodan bu sözlük çıkmıştır.
      </p>

    </div>
  );
}

export default ÖnsözText;
