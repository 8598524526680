import React from 'react';
import './horizontalAdd.css';
import { Col,Row } from 'react-bootstrap';

function HorizonalAdd(props) {
    return (
        <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0px",
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <img src={require("../../assets/img/lugatim_banner_sozluk.png")}  className='HorizontalImage'/>
        <img src={require("../../assets/img/lugatim_banner_lugatim.png")}  className='HorizontalImage'/>
        {/* <div  className='HorizontalAdd'>
                Yatay Reklam Alanı
        </div> */}
        {/* <div> */}
        {/* <div  className='HorizontalAdd2'> */}
                {/* Yatay Reklam Alanı */}
        {/* </div> */}
        {/* </div> */}
      </Row>
        
    );
}

export default HorizonalAdd;