import React, { Component, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Draggable from "react-draggable";
import arabicLayout from "simple-keyboard-layouts/build/layouts/arabic";
import farsiLayout from "simple-keyboard-layouts/build/layouts/farsi";
import turkishLayout from "simple-keyboard-layouts/build/layouts/turkish";

import { Colors } from "../../assets/Colors";
import { CloseButton, Dropdown, NavDropdown } from "react-bootstrap";

function KeyboardDeneme({ word, setWord ,setKeyboard,getWord}) {
  const [language, setLanguage] = useState("Türkçe");
  const [layout, setLayout] = useState(turkishLayout);
  const [shift, setShift] = useState("default");

  const onChange = (input) => {
    setWord(input);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();

    if (button==='{bksp}') {
      setWord(getWord().slice(0,-1));
    }

    if (button.includes('{')&&button.includes('}')) {
      
    }else{
      setWord(getWord()+button);
    }
  };

  const handleShift = () => {
    if (shift == "default") {
      setShift("shift");
    } else {
      setShift("default");
    }
  };


  return (
    <div>
      <Draggable cancel=".react-resizable-handle" defaultClassName="Draggable">
        <div style={{ padding: 5, backgroundColor: Colors.LightBlue,zIndex:100 }}>
          <div
            style={{
              backgroundColor: Colors.LightBlue,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems:'center'
            }}
          >
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={language}
              menuVariant="dark"
            >
              <NavDropdown.Item
                onClick={() => {
                  setLanguage("العربية");
                  setLayout(arabicLayout);
                }}
              >
                العربية
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  setLanguage("فارسی");
                  setLayout(farsiLayout);
                }}
              >
                فارسی
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  setLanguage("Türkçe");
                  setLayout(turkishLayout);
                }}
              >
                Türkçe
              </NavDropdown.Item>
            </NavDropdown>
            <CloseButton onClick={()=>setKeyboard(false)}/>
          </div>
          <div style={{height:5}}> </div>
          <Keyboard
            // onChange={onChange}
            onKeyPress={onKeyPress}
            layout={layout.layout}
            inputName="Search"
            layoutName={shift}
          />
        </div>
      </Draggable>
    </div>
  );
}

export default KeyboardDeneme;
