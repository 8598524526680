import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  RedirectFunction,
  Navigate,
  createBrowserRouter,
} from "react-router-dom";
import Takdim from "./pages/Takdim";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import { StrictMode, useState } from "react";
import Router from "./Routes";


export default function App() {
  const [word,setWord]=useState('');

  const getWord=()=>{
    return (word);
  };

  const setMainWord=(word)=>{
    setWord(word);
  }

  return (
      <BrowserRouter>
        {/* <Routes>
          <Route exact path="/" element={<HomePage />}>
            <Route path="s/" element={<HomePage />}>
              <Route path=":word" element={<HomePage />} />
              <Route path=":word/:page" element={<HomePage />} />
            </Route>

            <Route path="e/" element={<HomePageForTextSearch />}> 
              <Route path=":word" element={<HomePageForTextSearch />} />
              <Route path=":word/:page" element={<HomePageForTextSearch />} />
            </Route>

            <Route path="takdim" element={<Takdim />} />
            <Route path="takdim/m" element={<TakdimMobile />} />
            <Route path="iletisim" element={<Contact />} />
            <Route path="iletisim/m" element={<ContactMobile />} />
          </Route>
        </Routes> */}
       
        
        <Router/>
        
      </BrowserRouter>
  );
}

