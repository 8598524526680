import React from "react";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import Space from "../Space/Space";
import "./contactMap.css";
import AddressCard from "../AddressCard/AddressCard";

function ContactMap(props) {
  return (
    <div>
      <text className="ContactFormTitle">Bize Ulaşın</text>
      <HorizontalLine />

      <Space top={"3.2vh"} />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6021.554093190744!2d28.967466092776963!3d41.00825286364062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caba21c2e653f3%3A0xe400a678105d55c1!2zS3ViYmVhbHTEsSBBa2FkZW1pc2kgS8O8bHTDvHIgVmUgU2FuYXTEsSBWYWtmxLE!5e0!3m2!1sen!2str!4v1702130247476!5m2!1sen!2str"
        style={{ width: "100%", height: "34.2vh" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
      <Space top={"20px"} />
      <p className="VakıfTitle">Kubbealtı Akademisi Kültür ve Sanat Vakfı</p>
      <Space top={"20px"} />

      <AddressCard
        title={"Merkez"}
        address={"Fevzipaşa Caddesi No: 175 Fatih/İstanbul"}
        phone={"0 (212) 521 19 95, 0 (212) 491 04 79"}
        faks={"0 (212) 491 05 79"}
      />
      <Space top={"20px"} />

      <AddressCard
        title={"Çemberlitaş Binası"}
        address={"Köprülü Medresesi, Peykhane Sokağı, No: 3 Çemberlitaş/İstanbul"}
        phone={"0 (212) 516 23 56, 0 (212) 518 92 09"}
        faks={"0 (212) 638 02 72"}
      />
    </div>
  );
}

export default ContactMap;
