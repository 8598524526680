import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Colors } from "../../assets/Colors";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import Space from "../Space/Space";
import "./Bagis.css";
import Sticky from "react-stickynode";
import DonationCard from "../DonationCard/DonationCard";
import Osmanlica from "../Osmanlica/Osmanlica";
import KitapReklam from "../KitapReklam/KitapReklam";
import SlideShow from "../SlideShow/SlideShow";
import CourseSlider from "../CourseSlider/CourseSlider";
import Announcement from "../Announcement/Announcement";

function BagisPageBody(props) {
  return (
    <Container
      fluid
      className="PageBody"
      id="PageBody"
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        backgroundColor: Colors.White,
        alignContent: "stretch",
        // gap: 20,
        justifyContent: "flex-start",
        //   minHeight:"75vh"
      }}
      // className="ContactPageBody"
    >
      <Row>
        <Col xs={12} lg={9} style={{ gap: 20 }}>
          <p className="PrivacyTitle">Bağış</p>
          <HorizontalLine />
          <Space top={20} />

          <p className="BagisNormalText">
            Bu hizmetlerin devam etmesi ve daha ileriye taşınabilmesi için
            katkılarınızı bekliyoruz.
          </p>
          <Space top={20} />
          <p className="BagisNormalText">
            <span className="BagisBoldText">Kurum : </span>
            Kubbealtı Akademisi Kültür ve Sanat Vakfı
          </p>

          <p className="BagisNormalText">
            <span className="BagisBoldText">Banka : </span>
            Vakıfbank
          </p>

          <p className="BagisNormalText">
            <span className="BagisBoldText">IBAN : </span>
            TR78 0001 5001 5800 7292 7274 22
          </p>
        </Col>
        <Col xs={12} lg={3}>
          <Sticky
            top="#FixedHeader"
            bottomBoundary="#PageBody"
            shouldFreeze={() => {
              if (window.screen.width <= 991) {
                return true;
              } else {
                return false;
              }
            }}
          >
            {/* <Space top={"1vh"}></Space> */}
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Col xs={12} lg={1}></Col> */}
              <Col xs={12} lg={11}>
                {/* <DonationCard /> */}

                {/* <KitapReklam /> */}
                {/* <SlideShow/> */}

                {/* Duyuru */}
                <Announcement/>

              </Col>
            </Row>
            <div style={{ height: 15 }}></div>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col xs={12} lg={11}>
                {/* <Osmanlica /> */}
                
                <CourseSlider/>
              </Col>
            </Row>
          </Sticky>
        </Col>
      </Row>
    </Container>
  );
}

export default BagisPageBody;
