import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./mobileApps.css";
import { ApppleIcon, GooglePlayIcon } from "../../assets/svg";

function MobileAppsCard({ type }) {
  let Icon = ApppleIcon;
  if (type != "App Store") {
    Icon = GooglePlayIcon;
  }
  const appLink =
    type !== "App Store"
      ? "https://play.google.com/store/apps/details?id=com.kubbealti.lugatim"
      : "https://apps.apple.com/tr/app/lugatım/id1273594788";
  return (
    <a style={{textDecoration:'none'}} href={appLink} target="_blank">
      <Container
        fluid
        className="MobileAppsCard"
        style={{
          width: 135,
          // height: "4vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: 0,
        }}
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            xs={4}
            style={{
              padding: 0,
              paddingLeft: "5px",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Icon width={"25px"} height={"25px"} color={"#fff"} />
          </Col>
          <Col>
            <Row className="MobileAppCardTitle">Hemen İndirin</Row>

            <Row className="MobileAppCardTypeText">{type}</Row>
          </Col>
        </Row>
      </Container>
    </a>
  );
}

export default MobileAppsCard;
