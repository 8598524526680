import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Colors } from "../../assets/Colors";

import "./contactPageBody.css";
import ContactForm from "../ContactForm/ContactForm";
import ContactMap from "../ContactMap/ContactMap";
import DonationCard from "../DonationCard/DonationCard";
import Space from "../Space/Space";
import Sticky from "react-stickynode";
import Osmanlica from "../Osmanlica/Osmanlica";
import CourseSlider from "../CourseSlider/CourseSlider";
import SlideShow from "../SlideShow/SlideShow";
import Announcement from "../Announcement/Announcement";

function ContactPageBody(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container
      fluid
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        backgroundColor: Colors.White,
        alignContent: "stretch",
      }}
      className="ContactPageBody"
    >
      <Row>
        <Col xs={12} md={12} lg={9}>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <ContactForm />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <ContactMap />
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          md={12}
          lg={3}
          className="ContactPageThirdCol"
          style={{ gap: 15 }}
        >
          <Space top={"1px"}></Space>
          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* <Col lg={1}></Col> */}
            <Col lg={11}>
              {/* <DonationCard /> */}
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* <Col lg={1}></Col> */}
            <Col lg={11}>

              {/* Duyuru */}
              <Announcement/>

              {/* <Osmanlica/> */}
                <CourseSlider/>

            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactPageBody;
