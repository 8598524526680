import React from "react";
import "../texts.css";

function BaskılarText(props) {
  return (
    <div id="Baskılar">
      <text className="Title" id="BaskilarTitle">
        Baskılar
      </text>

      <p className="Paragraph">
        <br />
        <text >1. Baskı: Kasım 2005</text>
        <br />
        <text >2. Baskı: Mart 2006</text>
        <br />
        <text >3. Baskı: Haziran 2008</text>
        <br />
        <text>4. Baskı: Ocak 2011</text>
      </p>
    </div>
  );
}

export default BaskılarText;
