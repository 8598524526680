import React from "react";
import { Colors } from "../../assets/Colors";

function SlideShowCard({name,img,url}) {
  return (
    <a
      className="SlideShowCard"
      href={url}
      target="_blank"
    >
      <img src={img} className="SlideShowImage"></img>
      {/* <span>{name}</span> */}
    </a>
  );
}

export default SlideShowCard;
