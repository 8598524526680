import React, { useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { Colors } from "../../assets/Colors";
import "../../assets/oldCss/klugat20230309.css";
// import "./HomePageBody.css";

import Sticky from "react-stickynode";
import PageTabs from "../PageTabs/PageTabs";
import DonationCard from "../DonationCard/DonationCard";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import Space from "../Space/Space";
import CustomPagination from "../Pagination/Pagination";
import { AudioIcon } from "../../assets/svg";
import axios from "axios";
import AudioReturner from "../Audio/AudioReturner";
import MobilePagination from "../MobilePagination/MobilePagination";
import Share from "../Share/Share";
import Osmanlica from "../Osmanlica/Osmanlica";
import HorizonalAdd from "../HorizontalAdd/HorizonalAdd";
import KitapReklam from "../KitapReklam/KitapReklam";
import SlideShow from "../SlideShow/SlideShow";
import SlideShowMobile from "../SlideShowMobile/SlideShowMobile";
import CourseSlider from "../CourseSlider/CourseSlider";
import Announcement from "../Announcement/Announcement";

function MobileHomePageBody({ data, page, totalPage, word,searchInText }) {
  const content = data;

  const audioRef = useRef(null);
  const [audioId, setAudioId] = useState("678589");

  try {
    audioRef.current.onended = () => {
      setAudioId("");
      // alert("bitti");
    };
  } catch (error) {
    
  }

  try{
    audioRef.current.onerror=()=>{
      setAudioId("");
    }

  }catch{

  }
 
  return (
    <Container
      fluid
      
      className="PageBody"
      id="HomePageBody"
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        backgroundColor: Colors.White,
        alignContent: "stretch",
        minHeight:"100vh",
        gap:"15px"
      }}
    >
      <audio preload="none" ref={audioRef}>
        <source
          src={`https://lugatim.com/static/media/${audioId}.wav`}
          type="audio/wav"
        />
        {/* <source src={require(`./675105.wav`)} type="audio/wav" /> */}
      </audio>
      <HorizonalAdd/>

      <Row>
        <Col xs={12} lg={9}>
          {content.map((element) => {
            return (
              <div style={{ textAlign: "justify", marginBottom: 45 }}>
                <div
                  style={{
                    color: Colors.Blue,
                    fontSize: 25,
                    textAlign: "initial",
                    flexDirection: "row",
                    justifyContent:'space-between',
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div style={{
                    color: Colors.Blue,
                    fontSize: 25,
                    textAlign: "initial",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}>
                  {element.kelime}
                  <div
                      onClick={() => {
                        setAudioId(element.id);
                        audioRef.current.load();
                        audioRef.current.play();
                      }}
                    >
                      <AudioReturner id={element.id} audioId={audioId}/>
                    </div>
                  </div>

                  <Share word={element.kelime}  mean={element.noHtml}/>
                </div>
                <HorizontalLine />
                <Space top={15} />
                {parse(element.anlam)}
              </div>
            );
          })}
          <MobilePagination
            pageNumber={page}
            totalPages={totalPage}
            word={word}
            searchInText={searchInText}
          />
        </Col>
        <Col
          xs={12}
          lg={3}
          // style={{ position: "fixed" }}
          // style={{position:'fixed',}}
          // style={{position:'fixed',}}
          // style={{
          //   display: "flex",
          //   alignItems: "flex-end",
          //   flexDirection: "column",
          // }}
        >
          <Sticky
            top="#FixedHeader"
            bottomBoundary="#Footer"
            shouldFreeze={() => {
              if (window.screen.width <= 991) {
                return true;
              } else {
                return false;
              }
            }}
          >
            <Space top={"1vh"}></Space>
            <Row>
              <Col xs={12} lg={1}></Col>
              <Col xs={12} lg={11}>
                {/* <KitapReklam/> */}
                {/* <SlideShow/> */}

                {/* Duyuru */}
                <Announcement/>
              </Col>
            </Row>
            <div style={{ height: 15 }}></div>
            <Row>
              <Col xs={12} lg={1}></Col>
              <Col xs={12} lg={11}>
                {/* <Osmanlica/> */}
                <CourseSlider/>
              </Col>
            </Row>
          </Sticky>
        </Col>
      </Row>
    </Container>
  );
}

export default MobileHomePageBody;
