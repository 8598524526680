import React from "react";
import "./socialMediaButtons.css";
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  XIconSvg,
} from "../../assets/svg";
import { Container } from "react-bootstrap";

function SocialMediaButtons(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: "0.45vw",
      }}
    >
      <a
        style={{
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
        target="_blank"
        href="https://www.facebook.com/p/Kubbealt%C4%B1-Lugat%C4%B1-Misalli-B%C3%BCy%C3%BCk-T%C3%BCrk%C3%A7e-S%C3%B6zl%C3%BCk-100077739574752/"
      >
        <FacebookIcon
          width={"25px"}
          height={"24px"}
          color={"#FFF"}
          href={"google.com"}
        />
      </a>
      <a
        style={{ cursor: "pointer" }}
        target="_blank"
        href="https://twitter.com/kubbealtilugat"
      >
        <XIconSvg width={"25px"} height={"26px"} color={"#FFF"} />
      </a>
      <a
        target="_blank"
        style={{ cursor: "pointer" }}
        href="https://www.instagram.com/lugatkubbealti/"
      >
        <InstagramIcon width={"25px"} height={"25px"} color={"#FFF"} />
      </a>
    </div>
  );
}

export default SocialMediaButtons;
