import React, { useState } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import Contact from "./pages/Contact";
import HomePage from "./pages/HomePage";
import HomePageForTextSearch from "./pages/HomePageForTextSearch";
import Takdim from "./pages/Takdim";
import TopHeader from "./components/TopHeader/TopHeader";
import FixedHeader from "./components/FixedHeader/FixedHeader";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import Footer from "./components/Footer/Footer";
import SecondaryFooter from "./components/SecondaryFooter/SecondaryFooter";
import HomePageMobile from "./pages/HomePageMobile";
import HomePageForTextSearchMobile from "./pages/HomePageForTextSearchMobile";
import Bagis from "./pages/Bagis";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export default function Router() {
  const [word, setWord] = useState("");

  const getWord = () => {
    return word;
  };

  const setMainWord = (word) => {
    setWord(word);
  };
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/s/" />,
    },

    {
      path:"/",
      element: (
        <>
          {/* <TopHeader /> */}
          <FixedHeader word={word} setMainWord={setMainWord} />
          <MobileHeader />
          <main>
            <Outlet />
          </main>
          <Footer />
          <SecondaryFooter />
        </>
      ),
      children: [
        {
          path: "s",
          element: <HomePage />,
          children: [
            { path: ":word", element: <HomePage /> },
            { path: ":word/:page", element: <HomePage /> },
          ],
        },

        {
          path: "e",
          element: <HomePageForTextSearch />,
          children: [
            { path: ":word", element: <HomePageForTextSearch /> },
            { path: ":word/:page", element: <HomePageForTextSearch /> },
          ],
        },

        {
          path: "iletisim",
          element: <Contact />,
        },

        {
          path: "bagis",
          element: <Bagis />,
        },

        {
          path: "gizlilik-politikasi",
          element: <PrivacyPolicy />,
        },

        {
          path: "muellif-ve-lugat-hakkinda",
          element: <Takdim />,
        },
      ],
    },

    {
        path:"/m",
        element: (<Navigate to="/m/s/" />),
      },

      {
        path: "/m/s/",
        element: <HomePageMobile />,
        children: [
          { path: ":word", element: <HomePageMobile /> },
          { path: ":word/:page", element: <HomePageMobile /> },
        ],
      },

      {
        path: "/m/e/",
        element: <HomePageForTextSearchMobile />,
        children: [
          { path: ":word", element: <HomePageForTextSearchMobile /> },
          { path: ":word/:page", element: <HomePageForTextSearchMobile /> },
        ],
      },

      {
        path: "/m/iletisim/",
        element: <Contact />,
      },

      {
        path: "/m/bagis",
        element: <Bagis />,
      },

      {
        path: "/m/gizlilik-politikasi",
        element: <PrivacyPolicy />,
      },

      {
        path: "/suggestWord/",
        element: <Navigate to="/m/s/" />,
      },


      {
        path: "/m/muellif-ve-lugat-hakkinda/",
        element: <Takdim />,
      },

  ]);
}
