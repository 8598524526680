import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Colors } from "../assets/Colors";
import HorizontalLine from "../components/HorizontalLine/HorizontalLine";
import Space from "../components/Space/Space";
import BagisPageBody from "../components/BagisPageBody/BagisPageBody";

function Bagis(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container fluid style={{ padding: 0 }}>
      <BagisPageBody/>
    </Container>
  );
}

export default Bagis;
