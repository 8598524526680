import React from "react";
import "../texts.css";

function BasındaText(props) {
  return (
    <div id="Basında">
      <text className="Title" id="BasindaTitle">
        Basında
      </text>

      <p className="Paragraph">
        <br />
        <text className="Subtitle">
          Prof.Dr. Orhan OKAY, Kubbealtı Akademi Mecmuası, Ocak 2006
        </text>
        <br />
        "Bir Kubbealtı yayını olan Misalli Büyük Türkçe Sözlük kültür hayâtımıza
        önemli yenilikleriyle girdi. Gerek meslek îcabı, gerekse ilgi duyarak
        sözlüklerle meşgul olmuş olanların sathî bir bakışla bile fark
        edecekleri gibi bu sözlük Türk lugatçılığında birçok ilkleri
        gerçekleştirmiştir. Önce kelime dağarcığı bakımından günümüzün en büyük
        sözlüğüdür. İkinci olarak hiçbir sözlükte etimolojik bilgiler bu kadar
        zengin değildir. Misaller, birçok sözlüğün birbirinden aktarma usûlü
        yerine, çok değişik kaynaklar taranarak ve çok defa birden fazla örnek
        hâlinde verilmiştir. Belki pek çok dilden daha zengin olan deyimlerimizi
        de en çok karşılayan sözlük olmuştur. Osmanlıcadır bahanesiyle ve
        unutulmuş, terkedilmiş diye sözlüklerin çoğunun ihmal ettiği, fakat
        bilen ve kullanan nesillerin hâlen hayatta olduğu pek çok kelime, kavram
        ve terimin yer alması da başka bir özelliğini teşkil etmektedir. Şahsen
        hayâtımda ilk defa bir sözlüğü sâdece ihtiyâcım olduğu zaman değil, zevk
        için ve tecessüsle, bir kitap gibi sayfa sayfa okuduğumu da belirtmek
        isterim."
        <br />
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">
          Selim İLERİ, Kubbealtı Akademi Mecmuası, Ocak 2006
        </text>
        <br />
        "Sayın İlhan Ayverdi'nin büyük emeği bu sözlük, haftalardır beni
        büyülüyor. Türkçe'nin târih içindeki inanılmaz serüvenine yol alıyorum.
        Ana dilimizin zenginliğine açılıyorum. Bu sözlükte ana dilimizin
        romanını okuyorum. Türkçe'nin inceliklerini, güzelliğini, deyim ve
        mecazda uçsuz bucaksız servetini yaşıyorum. Diyebilirim ki, Türkçe'yi
        silbaştan tadıyorum."
        <br />
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Hakkı DEVRİM, Radikal, 20 Kasım 2007</text>
        
        <br />
        "Dilimin ucunda hazır bekleyen kelimeler arasında görgülü ve görgüsüz
        sıfatları da var. İsraf etmeyeyim diye, bu değerlendirmeleri imsak ile
        kullanmaya, hele birine görgüsüz demek geldiyse içimden, dilimi tutmaya
        gayret etmez de değilim.
        <br />
        <br />
        Görgüsüzlük'ten giderek daha çok rahatsız olduğumu, bırakın benim
        tedirginliğimi, sözünü ettiğim davranış biçiminin yaşadığımız dünyada
        hatta bir hayat tarzına dönüştüğünü de kısaca söyledikten sonra, gelin
        sizlerle, olumlusu da olumsuzu da çok kullanılan bu iki sıfatın anlamı
        konusunda bir anlaşmaya varalım.
        <br />
        <br />
        Size sık sık Ayverdi Sözlüğü'nden söz ediyorum. (Asıl adı Kubbealtı
        Lugatı, Misalli Büyük Türkçe Sözlük. Hoşgörsünler ben «Ayverdi Sözlüğü»
        demeyi tercih ediyorum.) Sözlükler (evde ve gazetede) hemen arkamdaki
        raflarda durur. «Düzeyli birlikteliğimiz» bir yılı geçti. Ve hayli zaman
        var ki, Ayverdi benim en çok başvurduğum sözlük oldu. Bu yeni sözlük 3
        500 küsur sayfalık bir eser. Kağıdı, dizgisi, hurufatı mükemmel üç güzel
        cilt halinde. Ben, merak ettiğim yeni sözlükleri alır, hakkında karara
        varmadan uzunca bir süre beklemeyi, yeni sözlüğümle de hemhal olmayı
        tercih ederim. Çok sık baktığım, kıyaslama gerekince elime aldığım, bir
        hatıra olarak sakladığım sözlükler var kitaplığımda. En çok Ayverdi
        Sözlüğü'ne başvurmamın bir başka sebebini söyleyeyim size. Anlambilim
        (semantik) açısından çok başarılı bir sözlük. Sık sık aynı kelimenin
        çeşitli sözlüklerdeki anlam tariflerini karşılaştırma alışkanlığıma
        dayanarak söylüyorum bunu.
        <br />
        <br />
        12 ciltlik Meydan Larousse ansiklopedik sözlüğündeki terim anlamları yok
        bu sözlükte. Ama örnek cümleler açısından cömert bir sözlük; deyimler,
        deyişler açısından da öyle. Aynı zamanda pek kullanışlı Osmanlıca-Türkçe
        bir sözlük de diyebilirim. Bunlara, Arapça, ve Farsça dışındaki yabancı
        dillerden alınmış bir Yabancı Kelimeler Sözlüğünü de ekleyebiliriz.
        <br />
        <br />
        Tek bir sözlükten daha çoğu da beklenemez sanırım. İlköğretim çağından
        hemen sonra öğrencilerin de faydalanmaya başlayabileceği bir sözlüktür.
        Herkese tavsiye ediyorum."
        <br />

        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Taha AKYOL, Milliyet, 16 Kasım 2005</text>
        <br />
        "Türkçe'nin mutlu bir günü, çünkü dilimiz mükemmel bir sözlüğe kavuştu.
        Dilimizin zenginliğini yansıtan bir sözlüğümüz var artık."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Ergun GÖZE, Tercüman, 17 Kasım 2005</text>
        <br />
        "34 yıl dile kolay. Bir lugat için otuz dört yıl. Ama bu bir gerçek.
        Bayrağı otuz dört sene taşımaktan yılmayan ve hedefine ulaştıran İlhan
        Ayverdi'yi hayırla andım. Kendisine otuz dört senenin toplamı olan bu
        eserin basılmışı hasta yatağında verilince de, Ahmet Hamdi Tanpınar
        ‘Muradiye sabrın acı meyvesi' diyor, bu eser de benim otuz dört senelik
        sabrımın acı meyvesi dediğini Sinan Uluant açış konuşmasında nakletti.
        Aynı kanaatte değilim. Telefonda kendisini tebrik ederken ‘Acı değil
        sabrın tatlı meyvesi' dedim"
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Emre AKÖZ, Sabah, 18 Kasım 2005</text>
        <br />
        "Ben bir uzman değilim. Ama kendimce sözlüğü inceledim. Gördüğüm
        kadarıyla şâhâne bir çalışma olmuş. Sözlüğü hazırlayanların komplekse
        girmeden Türk Dil Kurumu tarafından eskiden
        ‘üretilmiş-türetilmiş-uydurulmuş' kelimeleri de aldığını memnuniyetle
        gördüm. Kubbealtı Lugatı'nı gönül rahatlığı ile satın alabilirsiniz."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Doğan HIZLAN, Hürriyet , 19 Kasım 2005</text>
        <br />
        "Bu zengin sözlükte gerçekten aradığınız bir çok yeni-eski kelimeyi
        bulabilirsiniz. Sözlükte birçok Osmanlıca kelimeyi de örneklerle
        bulacaksınız. Böylece Türkçe Sözlük ile Osmanlıca Sözlük'ün
        özelliklerini bir arada barındıran bir sözlük bu."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Servet KABAKLI, Tercüman, 19 Kasım 2005</text>
        <br />
        "Şu an elimizde 34 yıl süren bir alın teri ve göz nuruyla hazırlanmış,
        rahatlıkla iftihar edebileceğimiz altın kıymetinde bir sözlüğümüz var!
        Milletimizin minnet ve şükran borçlu olduğu çok muhterem İlhan Ayverdi
        Hanımefendi'nin gerçek mânâda bir ‘ömür verdiği' üzerinde kuyumcu
        titizliğiyle çalıştığı ‘Misalli Büyük Türkçe Sözlük'ün, yâni Kubbealtı
        Lugatı'nın yayınlanmasıyla, Türkçe'nin engin ufukları açılmış
        bulunuyor."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Sabahat EMİR, Türkiye, 19 Kasım 2005</text>
        <br />
        "Türkçe'nin yüz akı diyebileceğimiz bu dev eser; sabrın, ciddi ve titiz
        çalışmanın yüz güldüren ürünü. Dile kolay, tam 34 yıl... Normal bir
        ömrün yarısı... Türkçe'ye saygı ve sevginin, ona sâhip çıkmanın bundan
        daha anlamlı bir ifâdesi olabilir mi? Türkçe'nin ihmal edildiği,
        gelişigüzel kullanımlarla horlandığı günümüzde böyle kıymetli bir eseri
        hazırlayıp yayınlamakla ona lâyık olduğu saygıyı gösteren Kubbealtı
        Akademisi Kültür ve Sanat Vakfı'nı kutluyorum."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">
          Muhiddin NALBANTOĞLU, Yeniçağ 19 Kasım 2005
        </text>
        <br />
        "Hatırı sayılır bir büyük dil uzmanı olan İlhan Ayverdi Hanımefendinin
        kitabın tanıtım şöleninde banttan yaptığı konuşmayı dinledim. Böyle bir
        âbide esere ömrünün en güzel yıllarını cömertçe harcayan bu güzel ve
        asil insanın çok mütevazı konuşması orada bulunanların hiçbir zaman
        unutamayacakları bir konuşmadır."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Hilmi YAVUZ, Zaman 23 Kasım 2005</text>
        <br />
        "Bir kurulun öncülüğünde başlanan ‘Misalli Büyük Türkçe Sözlük'
        çalışmalarının yazımına 1976'da girişilmiş, 2004 yılında
        tamamlanabilmiş. Neredeyse 35 yıl süren büyük ve sabırlı bir entelektüel
        faaliyet! Üç ciltte 3644 sayfada 61.000 ana madde ve ara madden oluşan
        sözlük, 35.000 deyim ile 400 yazarın 1.000'e yakın eserinin taranmasıyla
        elde edilen 100.000 örnek içeriyor."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Hasan PULUR, Milliyet, 26 Kasım 2005</text>
        <br />
        "Türkçe önemli bir kaynak daha kazandı: Kubbealtı Lugatı, Misalli Büyük
        Türkçe Sözlük. Yâni sözlük sâdece yaşayan Türkçemizin değil, târihi
        seyri içinde Türk dilinin kazanmış olduğu kullanımları da belgeleyerek
        Türkçe'nin zenginliğini gösteriyor."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Ahmet Turan ALKAN, Aksiyon, 28 Kasım 2005</text>
        <br />
        "Yarınlar için artık daha müsterih tavır takınabiliriz çünkü en azından
        sırtımızı yaslayabileceğimiz bir sağlam lugatımız daha var artık. Bu
        hayırlı ve görkemli esere emek ve destek verenleri temsilen İlhan
        Ayverdi hanımefendiye lisanımız, irfânımız ve medeniyetimiz nâmına
        binlerce şükran borçluyuz."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">
          Yavuz Bülent BÂKİLER, Tercüman, 06 Aralık 2005
        </text>
        <br />
        "Şimdi önümde Kubbealtı Akademisi Kültür ve Sanat Vakfı tarafından
        hazırlanmış üç ciltlik mükemmel bir sözlük var. İsmi: Asırlar boyu
        târihi seyri içinde Misalli Büyük Türkçe Sözlük. Bu sözlük, bir Türkçe
        hazinesi. Şahsi kütüphânemde, Türkçe üzerine hazırlanmış sözlük sayısı
        49 cilttir. Bu 49 cilt arasında, muhterem İlhan Ayverdi Hanımefendi
        tarafından, tam 34 sene emek verilerek hazırlanan Misalli Büyük Türkçe
        Sözlük, birden bire diğer sözlüklerin önüne geçti. Misalli Büyük Türkçe
        Sözlük, her akıllı Türk'ün, mutlaka sahip olması gereken bir mübârek
        eser. Almakta gecikmeyin."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Beşir AYVAZOĞLU, Tercüman, 11 Aralık 2005</text>
        <br />
        "Okuyup yazarken artık kısa adı Kubbealtı Lugatı olan Misalli Büyük
        Türkçe Sözlük'ü kullanıyorum. Kubbealtı Kültür ve Sanat Vakfı tarafından
        otuz dört yıllık bir çalışma sonunda tamamlanan bu sözlük büyük bir
        boşluğu doldurmuştur. İnanın, hangi kelimeye baktımsa, tatmin oldum.
        Aslında Türkçe için "misalli" bir sözlük elzemdi. Bu zavallı dil o kadar
        hırpalandı ki, kelime ve deyimleri yerli yerinde kullanabilen biriyle
        karşılaştığımızda bağrımıza basmak istiyoruz. Kubbealtı Lugatı, kelime
        ve deyimlerin nasıl kullanılacağını dikkatle seçilmiş misallerle
        göstermesi bakımından ayrı bir önem taşıyor. Şunu rahatlıkla
        söyleyebilirim: Kubbealtı Lugatı'na ne zaman müracaat et-sem, önüme
        Türkçe'nin zenginlikleri ve geniş ifâde imkânları seriliyor ve Yahya
        Kemal'in ‘Bu dil ağzımda annemin sütüdür' mısrâında ne demek istediğini
        daha iyi anlıyorum."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Mehmet Y. YILMAZ, Hürriyet, 14 Aralık 2005</text>
        <br />
        "Sözlüklere ve ‘kelimelere' meraklı olduğum için yeni çıkan Kub-bealtı
        Lugatı'nı da hemen satın aldım. 3548 sayfalık sözlüğü karıştırır-ken
        içim hem rahatladı, hem karamsarlığa kapıldım. Bu kadar zengin bir dile
        sâhipken, nasıl bu hâle düştük diye üzüldüm."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Osman AKKUŞAK, Yeni Şafak, 15 Aralık 2005</text>
        <br />
        "Bu büyük esere vücud verenlerin kazandığı şeref de büyüktür... Kültür
        Bakanı Sayın Atillâ Koç'un, bütün kütüphânelere; Milli Eğitim Bakanı
        Sayın Hüseyin Çelik'in de okul kütüphânelerine birer adet alınması için
        yetkilerini kullanması dilimiz ve kültürümüz adına bir vazifedir."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle" >
          İskender PALA, Zaman, 15 Aralık 2005
        </text>
        <br />
        "Bugünlerde yeni bir sözlük edindim; tam da istediğim türden. Zannederim
        birkaç hafta sözlük sayfaları, sütunları, maddeleri, alt başlıkları
        arasında beyitler okumayı, kelimelerin büyüsüyle mânâ keşiflerine ve
        zihnî yolculuklara dalıp zamanı unutmayı, roman okumaya tercih
        edeceğim."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">
          Sevinç ÇOKUM, Halka ve Olaylara Tercüman, 31 Mayıs 2006
        </text>
        <br />
        "Değerli dostum İlhan Ayverdi'nin himmeti, bilim adamlarının ve dil
        uzmanlarının çalışmalarıyla yürütülen, yıllar sonra gerçekleştirilen bu
        üç ciltlik sözlüğün Türkçe kelime haznesine can getireceğine inanıyorum.
        Lugat aynı zamanda unutulmuş, çöpe atılmış nice kelimeyi geri getiriyor,
        koruma altına alıyor. Kaybolan nüanslar, fakirleştirilen dilin uğradığı
        kayıplar üzerinde yeniden düşünmeye sevk ediyor bizi. Otuz küsur yıllık
        bu emek için, dert görmesinler."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Arslan TEKİN, Yeniçağ, 9 Aralık 2005</text>
        <br />
        "Anlamadığınız kelimeleri sözlükten takip edeceksiniz. 61 bin kelimelik
        yeni lugat çıktı: Kubbealtı Lugatı… Uzakta olduğum için henüz
        inceleyemedim ama sözlük çalışmasını başından beri biliyorum; çünkü,
        başlatanlar hocam ve benden önceki doktora yaptırdığı talebeleridir. Üç
        ciltlik kitabı herkesin temin etmesi mümkün değildir; devlet bu lugatı,
        üniversitelerin ve orta mekteplerinki de dahil bütün kütüphaneler için
        temin etmelidir."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Muhsin ÖZTÜRK, Aksiyon, 26 Aralık 2005</text>
        <br />
        "Kubbealtı Sözlüğü sadece edebî eserlerde yer alan kelimeleri değil,
        halk dilini, yaşayan kelimeleri de kapsıyor. Bu bakımdan sözlük,
        konuşulan dilin sözlüğü ya da yazılı tarihin sözlüğü tanımlamalarının
        dışında daha kapsayıcı bir yerde duruyor."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">Yılmaz ÖZTUNA, Türkiye, 21 Aralık 2005</text>
        <br />
        "Kubbealtı Lugati- Asırlar Boyu Târihî Seyri İçinde Misalli Büyük Türkçe
        Sözlük, 20. yüzyıl Türk dil, edebiyat ve ilminin önemli ve temel
        eserlerinden biri olarak elimizdedir. En uzun zaman çalışılarak çok
        ehliyetli bir heyetçe hazırlanmış bulunması bakımından da edebiyat
        tarihlerimizde belirtilecektir."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle" >
          Mehmed NİYAZİ, Zaman, 3 Ocak 2008
        </text>
        <br />
        "Dil bir milletin şah damarıdır. Bunun için her devlet üniversiteler
        gibi bilim kurumlarıyla dilinin üzerinde hassasiyetle durur. Ne çare ki
        resmi makamlarımız dilin öneminin pek farkında değildirler. Zaten resmi
        merciler görevlerini yapmadığından idealistler büyük yüklerin altına
        girmek zorunda kalıyorlar. İlhan Ayverdi Hanımefendi'nin imzasıyla
        yayınlanan Misalli Büyük Türkçe sözlük'ü hazırlamak bizim gibi bi
        toplumda sivil bir kurumun altına gireceği bir iş olabilir mi? … Bu
        toprağın çocuğu olarak, varlığımıza temel çivisi çakan bu esere zerre
        kadar emeği geçenlere şükranlarımı sunuyorum."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">
          Mehmet Nuri YARDIM, Yeniçağ, 22 Kasım 2005
        </text>
        <br />
        "Koca bir ömrü şerefli ve ulvî bir dâvaya adayan ve bu muazzam yükün
        altından alnının akıyla çıkan İlhan Ayverdi, Türk milletine, Türklük
        âlemine ve İslâm dünyasına karşılığı ödenemeyecek muhteşem bir armağan
        bırakmıştır. Müesseselerin, vakıfların ve sivil toplum kuruluşlarının bu
        emeğe karşılık verecekleri ödüller, mükâfatlar, nişanlar, gösterecekleri
        iltifatlar olacaktır. Ancak âlimin kadrini en iyi takdir edecek ve ona
        ecrini verecek olan yüce Allah'tır."
        <br />
        
        <hr style={{borderTop:'dotted',borderTopWidth:'1px'}}></hr>
        <text className="Subtitle">
          Mustafa Şerif ONARAN, Cumhuriyet Kitap, 16 Şubat 2006
        </text>
        <br />
        "İlhan Ayverdi'nin Misalli Büyük Türkçe Sözlük'ü, içinde barındırdığı
        Osmanlıcayla Türkçenin görkemini gösteriyor. Sözcüklerdeki ayrım
        inceliklerini, anı yüklerini, anlam derinliklerini, çağrışım
        özelliklerini yeni bir sözcükte bulmak olanaksızdır. Ancak yeni
        sözcükler de zamana bu özellikleri kazanacaklardır. Misalli Büyük Türkçe
        Sözlük, Tarihsel Türkçe Sözlük özelliklerini taşımasa da, Türkçenin
        gücünü göstermesi, eski metinleri anlamayı kolaylaştırması bakımından
        yararlıdır."
      </p>
    </div>
  );
}

export default BasındaText;
