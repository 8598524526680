import React, { createRef, useEffect, useRef, useState } from "react";
import { AudioIcon, AudioIconFirst, AudioIconSecond } from "../../assets/svg";

function AudioReturner({ id,audioId, }) {
  const [audioIconNumber, setAudioIconNumber] = useState(3);

  useEffect(() => {
    setTimeout(()=>{
      switch (audioIconNumber) {
        case 1:
          setAudioIconNumber(2);
          break;
        case 2:
          setAudioIconNumber(3);
          break;
        case 3:
          setAudioIconNumber(1);
          break;
  
        default:
          break;
      }
    },500);
    
  },[audioIconNumber]);

  if (audioId!==id ) {
    return(
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor:'red',
          minHeight: 34,
          minWidth: 34,
        }}
      >
        
        <div>
          <AudioIcon width={"30px"} height={"100%"} />
        </div>
        {/* <AudioIcon width={"30px"} height={"100%"} /> */}
      </div>
    </div>
    );
  }

  
  

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor:'red',
          minHeight: 34,
          minWidth: 34,
        }}
      >
        <div style={{ display: audioIconNumber == 1 ? "flex" : "none" }}>
          <AudioIconFirst width={"30px"} height={"100%"} />
        </div>
        <div style={{ display: audioIconNumber == 2 ? "flex" : "none" }}>
          <AudioIconSecond width={"30px"} height={"100%"} />
        </div>
        <div style={{ display: audioIconNumber == 3 ? "flex" : "none" }}>
          <AudioIcon width={"30px"} height={"100%"} />
        </div>
        {/* <AudioIcon width={"30px"} height={"100%"} /> */}
      </div>
    </div>
  );
}

export default AudioReturner;
