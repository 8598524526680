import React from 'react';

function Line(props) {
    return (
        <div style={{width:1,height:36,backgroundColor:'#ffffff26'}}>
            
        </div>
    );
}

export default Line;