import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import BagisPageBody from "../components/BagisPageBody/BagisPageBody";
import PrivacyPageBody from "../components/PrivacyPageBody/PrivacyPageBody";

function PrivacyPolicy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container fluid style={{ padding: 0 }}>
      <PrivacyPageBody />
    </Container>
  );
}

export default PrivacyPolicy;
