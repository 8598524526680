import React, { useEffect } from 'react';
import { Container, } from 'react-bootstrap';
import TopHeader from '../components/TopHeader/TopHeader';
import Header from '../components/Header/Header';
import PageBody from '../components/PageBody/PageBody';
import Footer from '../components/Footer/Footer';
import SecondaryFooter from '../components/SecondaryFooter/SecondaryFooter';
import FixedHeader from '../components/FixedHeader/FixedHeader';

function Takdim(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <Container fluid style={{padding:0}}>
            {/* <TopHeader/> */}
            {/* <FixedHeader word={"Takdim"}/> */}
            {/* <Header/> */}
            <PageBody/>
            {/* <Footer/> */}
            {/* <SecondaryFooter/> */}
        </Container>
    );
}

export default Takdim;