import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { DonationIcon, HomeIcon, MenuFeatherIcon, MenuInfoIcon, MenuMailIcon, MenuSVG, SecurityIcon } from "../../assets/svg";
import "./burgerMenu.css";
import { useNavigate } from "react-router-dom";

function BurgerMenu({ pageWrapId, outerContainerId }) {
  const navigate = useNavigate();

  const [state, setState] = useState(false);

  return (
    <Menu
      pageWrapId={pageWrapId}
      outerContainerId={outerContainerId}
      customBurgerIcon={<MenuSVG width={"6vw"} height={"3vh"} color={"#FFF"} />}
      isOpen={state}
      onStateChange={(state) => setState(state.isOpen)}
      width={"70vw"}
      left
    >
      <li
        id="home"
        className="menu-item"
        onClick={() => {
          navigate("/");
          setState(true);
        }}
        style={{display:'flex',alignItems:'center'}}
      >
        <HomeIcon height={20} width={20} color={"white"} /> &nbsp; <p>Anasayfa</p>
      </li>
      <li
        id="about"
        className="menu-item"
        onClick={() => {
          navigate("/");
          setState(false);
        }}
        style={{display:'flex',alignItems:'center'}}
      >
        <MenuInfoIcon height={20} width={20} color={"white"} /> &nbsp; <p>Sözlük Kullanımı</p>
      </li>
      <li
        id="contact"
        className="menu-item"
        onClick={() => {
          navigate("/iletisim");
          setState(false);
        }}
        style={{display:'flex',alignItems:'center'}}
      >
        <MenuMailIcon height={20} width={20} color={"white"} /> &nbsp; <p> İletişim</p>
      </li>
      <li
        id="home"
        className="menu-item"
        onClick={() => {
          navigate("/muellif-ve-lugat-hakkinda");
          setState(false);
        }}
        style={{display:'flex',alignItems:'center'}}
      >
        <MenuFeatherIcon height={20} width={20} color={"white"} /> &nbsp; <p>Müellif ve Lugat Hakkında</p>
      </li>
      <li
        id="contact"
        className="menu-item"
        onClick={() => {
          navigate("/bagis");
          setState(false);
        }}
        style={{display:'flex',alignItems:'center'}}
      >
        <DonationIcon height={20} width={20} color={"white"} /> &nbsp; <p>Bağış</p>
      </li>
      <li
        id="contact"
        className="menu-item"
        onClick={() => {
          navigate("/gizlilik-politikasi");
          setState(false);
        }}
        style={{display:'flex',alignItems:'center'}}
      >
        <SecurityIcon height={20} width={20} color={"white"} /> &nbsp; <p>Gizlilik Politikası</p>
      </li>
    </Menu>
  );
}

export default BurgerMenu;
