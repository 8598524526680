import React from "react";
import "./share.css";
import {
  CopyIcon,
  FacebookIcon,
  FacebookIconSvg,
  WhatsappIconSvg,
  XIconSvg,
} from "../../assets/svg";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

function Share({ word, mean }) {
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
      
      <FacebookShareButton
        title={`${word} :  ${mean} \n \n`}
        url={`https://lugatim.com/s/${word.replace(/\s/g, "")}`}
        style={{ cursor: "pointer" }}
      >
        <FacebookIconSvg width={"30px"} height={"30px"} />
      </FacebookShareButton>

      <TwitterShareButton
        title={`${word} :  ${mean} \n \n`}
        url={`https://lugatim.com/s/${word.replace(/\s/g, "")}`}
        style={{ cursor: "pointer" }}
      >
        <XIconSvg width={"30px"} height={"30px"} />
      </TwitterShareButton>

      <WhatsappShareButton
        title={`${word} :  ${mean} \n \n`}
        url={`https://lugatim.com/s/${word.replace(/\s/g, "")}`}
        style={{ cursor: "pointer" }}
      >
        <WhatsappIconSvg width={"30px"} height={"30px"} color={"green"} />
      </WhatsappShareButton>

      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigator.clipboard.writeText(
            `${word} :  ${mean} \n \n https://lugatim.com/s/${word.replace(
              /\s/g,
              ""
            )}`
          ).then(()=>{})
        }}
      >
        <CopyIcon width={"30px"} height={"30px"} color={"grey"} />
      </div>
    </div>
  );
}

export default Share;
