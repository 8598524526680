import React from 'react';
import { Container, } from 'react-bootstrap';
import TopHeader from '../components/TopHeader/TopHeader';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import SecondaryFooter from '../components/SecondaryFooter/SecondaryFooter';
import FixedHeader from '../components/FixedHeader/FixedHeader';
import ContactPageBody from '../components/ContactPageBody/ContactPageBody';

function Contact(props) {
    return (
        <Container fluid style={{padding:0}}>
            {/* <TopHeader/> */}
            {/* <FixedHeader word={"İletişim"}/> */}
            {/* <Header/> */}
            <ContactPageBody/>
            {/* <Footer/> */}
            {/* <SecondaryFooter/> */}
        </Container>
    );
}

export default Contact;