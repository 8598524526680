import React from "react";
import "../texts.css";

function TakdimText(props) {
  return (
    <div id="Takdim">
      <text className="Title" id="TakdimTitle">Takdim</text>

      <p className="Paragraph">
        <br />
        Bu  satırların yazarı, halka yapılan hizmetin Hakk’a olduğunun şuûruna
        varmış olanların ve eserlerinin kendilerine değil yaptırana âit olduğunu
        bilen, kendilerini yapılması murat edilen için sâdece âlet ve vâsıta
        olarak görenlerin çırağıdır.
        <br />
        <br />
        On sekiz yaşından îtibâren ömrünü büyük mürebbî Ken’an Büyükaksoy’un
        yolunda sırasıyla Mehmet Örtenoğlu, Sâmiha Ayverdi ve Ekrem Hakkı
        Ayverdi gibi seçkin büyüklerle yaşamı ştır. Bir hizmet aşkı ve gayret
        varsa hepsi onlardandır. Gerektiği ölçüde muvaffak ve semereli
        olamamışsa sâdece bu kendine âittir. Affola!..
        <br />
        <br />
        Bu sözlüğe ne kadar çileli, ne derece zor bir iş olduğunu bilmeden otuz
        sene kadar önce başlanmıştı. Bu çetinden çetin işe dilimizin mâruz
        kaldığı içler acısı mâcerâya karşı elini kolunu bağlayarak
        oturulamayacağı için kalkışmış ve kendimizi birdenbire omuzlarımızı
        çatırdatacak bir yükün altında buluvermiştik. Ne var ki otuz seneye
        yaklaşan çalışma boyunca en büyük desteğimiz, her iki Ayverdi’nin
        teşvîki ve şöhret ve maddî menfaat kaygısından uzak olduğumuzu her an
        hissetmekten gelen bir emniyet ve samîmiyetle işin tamamlanacağına olan
        inancımız olmuştur.
        <br />
        <br />
        Bu çalışma Türk dili için bir nebze faydalı olacaksa günler ve geceler
        süren ağır mesâi altı nda geçen seneleri şikâyet değil şükür vesîlesi
        saymakta ve bu eseri bize gidilecek yolu ve her türlü hayrı gösteren
        aziz büyüklerimize ithaf etmekteyim.
      </p>

      <text className="Subtitle">
        İlhan Ayverdi
        <br />
        <br />
        Kasım, 2005
      </text>
    </div>
  );
}

export default TakdimText;
