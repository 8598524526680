import React from 'react';

function KitapReklam(props) {
    return (
        <a href='https://kubbealti.org.tr/kurs/uzaktan-egitim-farsca-baslangic-seviyesi' target='_blank'>
        <img src={require("../../assets/img/fars.png")} style={{width:'100%',cursor:'pointer'}}/>
        </a>
    );
}

export default KitapReklam;